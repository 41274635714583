exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-artificial-intelligence-index-js": () => import("./../../../src/pages/artificial-intelligence/index.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-index-js" */),
  "component---src-pages-aws-cloud-services-index-js": () => import("./../../../src/pages/aws-cloud-services/index.js" /* webpackChunkName: "component---src-pages-aws-cloud-services-index-js" */),
  "component---src-pages-backend-development-index-js": () => import("./../../../src/pages/backend-development/index.js" /* webpackChunkName: "component---src-pages-backend-development-index-js" */),
  "component---src-pages-big-data-index-js": () => import("./../../../src/pages/big-data/index.js" /* webpackChunkName: "component---src-pages-big-data-index-js" */),
  "component---src-pages-blog-[id]-js": () => import("./../../../src/pages/blog/[id].js" /* webpackChunkName: "component---src-pages-blog-[id]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-bot-development-index-js": () => import("./../../../src/pages/bot-development/index.js" /* webpackChunkName: "component---src-pages-bot-development-index-js" */),
  "component---src-pages-bubble-index-js": () => import("./../../../src/pages/bubble/index.js" /* webpackChunkName: "component---src-pages-bubble-index-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-category-[id]-js": () => import("./../../../src/pages/category/[id].js" /* webpackChunkName: "component---src-pages-category-[id]-js" */),
  "component---src-pages-category-index-js": () => import("./../../../src/pages/category/index.js" /* webpackChunkName: "component---src-pages-category-index-js" */),
  "component---src-pages-cms-development-index-js": () => import("./../../../src/pages/cms-development/index.js" /* webpackChunkName: "component---src-pages-cms-development-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-cryptocurrency-index-js": () => import("./../../../src/pages/cryptocurrency/index.js" /* webpackChunkName: "component---src-pages-cryptocurrency-index-js" */),
  "component---src-pages-custom-web-development-index-js": () => import("./../../../src/pages/custom-web-development/index.js" /* webpackChunkName: "component---src-pages-custom-web-development-index-js" */),
  "component---src-pages-data-science-index-js": () => import("./../../../src/pages/data-science/index.js" /* webpackChunkName: "component---src-pages-data-science-index-js" */),
  "component---src-pages-designing-services-index-js": () => import("./../../../src/pages/designing-services/index.js" /* webpackChunkName: "component---src-pages-designing-services-index-js" */),
  "component---src-pages-digital-marketing-index-js": () => import("./../../../src/pages/digital-marketing/index.js" /* webpackChunkName: "component---src-pages-digital-marketing-index-js" */),
  "component---src-pages-e-commerce-marketing-services-index-js": () => import("./../../../src/pages/e-commerce-marketing-services/index.js" /* webpackChunkName: "component---src-pages-e-commerce-marketing-services-index-js" */),
  "component---src-pages-full-stack-development-index-js": () => import("./../../../src/pages/full-stack-development/index.js" /* webpackChunkName: "component---src-pages-full-stack-development-index-js" */),
  "component---src-pages-graphic-designing-index-js": () => import("./../../../src/pages/graphic-designing/index.js" /* webpackChunkName: "component---src-pages-graphic-designing-index-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-how-we-work-index-js": () => import("./../../../src/pages/how-we-work/index.js" /* webpackChunkName: "component---src-pages-how-we-work-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-internet-of-things-index-js": () => import("./../../../src/pages/internet-of-things/index.js" /* webpackChunkName: "component---src-pages-internet-of-things-index-js" */),
  "component---src-pages-jobs-opening-[id]-js": () => import("./../../../src/pages/jobs-opening/[id].js" /* webpackChunkName: "component---src-pages-jobs-opening-[id]-js" */),
  "component---src-pages-jobs-opening-index-js": () => import("./../../../src/pages/jobs-opening/index.js" /* webpackChunkName: "component---src-pages-jobs-opening-index-js" */),
  "component---src-pages-kotlin-index-js": () => import("./../../../src/pages/kotlin/index.js" /* webpackChunkName: "component---src-pages-kotlin-index-js" */),
  "component---src-pages-machine-learning-index-js": () => import("./../../../src/pages/machine-learning/index.js" /* webpackChunkName: "component---src-pages-machine-learning-index-js" */),
  "component---src-pages-major-services-index-js": () => import("./../../../src/pages/major-services/index.js" /* webpackChunkName: "component---src-pages-major-services-index-js" */),
  "component---src-pages-mobile-app-development-index-js": () => import("./../../../src/pages/mobile-app-development/index.js" /* webpackChunkName: "component---src-pages-mobile-app-development-index-js" */),
  "component---src-pages-online-reputation-management-index-js": () => import("./../../../src/pages/online-reputation-management/index.js" /* webpackChunkName: "component---src-pages-online-reputation-management-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-progressive-app-development-index-js": () => import("./../../../src/pages/progressive-app-development/index.js" /* webpackChunkName: "component---src-pages-progressive-app-development-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-react-native-index-js": () => import("./../../../src/pages/react-native/index.js" /* webpackChunkName: "component---src-pages-react-native-index-js" */),
  "component---src-pages-search-engine-optimization-index-js": () => import("./../../../src/pages/search-engine-optimization/index.js" /* webpackChunkName: "component---src-pages-search-engine-optimization-index-js" */),
  "component---src-pages-social-media-optimization-index-js": () => import("./../../../src/pages/social-media-optimization/index.js" /* webpackChunkName: "component---src-pages-social-media-optimization-index-js" */),
  "component---src-pages-technologies-index-js": () => import("./../../../src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */),
  "component---src-pages-ui-ux-designing-index-js": () => import("./../../../src/pages/ui-ux-designing/index.js" /* webpackChunkName: "component---src-pages-ui-ux-designing-index-js" */),
  "component---src-pages-web-development-index-js": () => import("./../../../src/pages/web-development/index.js" /* webpackChunkName: "component---src-pages-web-development-index-js" */)
}

