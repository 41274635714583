/** external deps */
import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../../App.scss";
import { Navbar } from "../navigation";
import LOGO from "assets/ello_logo.svg";
// import LOGO from "assets/icons/ellocent-labs.svg";
import PrimaryButton from "../buttons/PrimaryButton";
import { Pophover } from "common/popover";
import { initI18 } from "utils/i18n";
import { useTranslation } from "react-i18next";
import { FooterSection } from "../footer";

initI18();

// we'll get location from gatsby
const Layout = ({ children, location, ...props }) => {
  const [selectlang, setSelectedLang] = useState("en");
  const { t } = useTranslation();
  useEffect(() => {
    if (location === undefined || location.hash) return;
    document.querySelector("body").scrollTo(0, 0);
  }, [location?.path]);

  const handleChange = (selectedLang) => {
    setSelectedLang(selectedLang);
  };

  const list = [
    {
      label: <Pophover title={t("navbar.whatwedo")} type="megamenu" />,
      path: "#",
    },
    { label: t("navbar.technologies"), path: "/technologies" },
    { label: t("navbar.industries"), path: "/industries" },
    {
      label: <Pophover title={t("navbar.portfolio")} />,
      path: "#",
    },
    { label: t("navbar.blog"), path: "/blog" },
    {
      label: <Pophover title={t("navbar.company")} type="company" />,
      path: "#",
    },
    {
      label: <PrimaryButton primaryBtn={t("navbar.contact_us")} />,
      path: "/contact-us",
    },
    // Language Selector Don't remove it
    // {
    //   label: (
    //     <LanguageSelect
    //       handleChange={handleChange}
    //       defaultLaguage={selectlang}
    //     />
    //   ),
    //   path: "#",
    // },
  ];
  return (
    <>
      <Navbar
        list={list}
        logo={LOGO}
        handleChange={handleChange}
        defaultLanguage={selectlang}
        location={location}
      />
      <div className="body-content">{children}</div>
      <FooterSection />
      {/* <div className="fixed">ver 13</div> */}
    </>
  );
};

export default Layout;
