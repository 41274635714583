import React from "react";

import "./style.scss";
import { IoIosMail, IoIosCall } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t, ready } = useTranslation();
  if (!ready) return "loading translations...";

  const contact = t("footer.contact", { returnObjects: true });

  return (
    <ul className="address-link">
      {contact.map((list, index) => (
        <li key={index}>
          {" "}
          <a
            href={list.href}
            target={list.target}
            className={`${list.href === "" && "disabled-link"}`}
          >
            <div className="list">
              {" "}
              <div className="contact-icon">
                {index === 0 ? (
                  <MdLocationOn />
                ) : index === 1 ? (
                  <MdLocationOn />
                ) : (
                  <IoIosCall />
                )}
              </div>
              <span>{list.content}</span>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
};
export default ContactUs;
