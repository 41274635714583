import React, { useState } from "react";
import "./style.scss";
import { Popover } from "antd";
import { SimplePopoverBody, MegaMenuBody } from "./popoverBodies";

export const Pophover = ({ list, title, type }) => {
  let Body = type === "megamenu" ? MegaMenuBody : SimplePopoverBody;
  const [visible, setVisible] = useState(false);
  const hide = () => setVisible(false);
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  return (
    <Popover
      placement="bottom"
      content={<Body list={list} hide={hide} type={type} />}
      trigger="hover"
      // trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      arrowPointAtCenter
    >
      <span className="popover-button">{title} </span>
    </Popover>
  );
};

export { SimplePopoverBody };
