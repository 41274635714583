import React, { useState } from "react";
import { Menu } from "antd";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";

import "./style.scss";

let getItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

let getMegamenu = (menuList) =>
  menuList.map((data) => {
    let childern = data.list.map((element) => {
      let value = getItem("- " + element.title, element.link, null);
      return value;
    });

    let value = getItem(data.title.title, data.title.title, null, childern);
    return value;
  });

export const MobileNavbar = () => {
  const [openKeys, setOpenKeys] = useState([""]);

  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";
  const menu_submenu = t("what_we_do_links", { returnObjects: true });
  const rootMenuKeys = [
    "WhatWeDo",
    "technologies",
    "industries",
    "Portfolio",
    "Company",
  ];
  const rootSubmenuKeys = [
    "Major Services",
    "Web Development",
    "Mobile App Development",
    "Designing Services",
    "Digital Marketing",
  ];

  const items = [
    getItem(t("navbar.whatwedo"), "WhatWeDo", null, getMegamenu(menu_submenu)),
    getItem(t("navbar.technologies"), "technologies", null, null),
    getItem(t("navbar.industries"), "industries", null, null),
    getItem(t("navbar.portfolio"), "Portfolio", null, [
      getItem(t("small-navbar-menu.projects"), "projects"),
      getItem(t("small-navbar-menu.products"), "products"),
    ]),
    getItem(t("navbar.company"), "Company", null, [
      getItem(t("small-navbar-menu.how_we_work"), "how-we-work"),
      getItem(t("small-navbar-menu.career"), "career"),
      getItem(t("small-navbar-menu.about_us"), "about-us"),
    ]),
  ];

  const onClick = (e) => {
    let path = e.key.replaceAll("/", "");
    navigate(`/${path}`);
  };

  const onOpenChange = (keys) => {
    let check = ["WhatWeDo"];
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.includes(latestOpenKey)) {
      check.push(latestOpenKey);
      setOpenKeys(check);
    } else if (rootMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div className="mobile-nav">
      <Menu
        onClick={onClick}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{
          width: "100%",
        }}
        mode="inline"
        items={items}
      />
    </div>
  );
};
