import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";

const FooterLinks = ({ path }) => {
  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";
  const links = t(path, { returnObjects: true });

  return (
    <ul>
      {links.map((list, index) => (
        <li key={index}>
          <Link to={list.link}>{list.name}</Link>
        </li>
      ))}
    </ul>
  );
};
export default FooterLinks;
