import React from "react";
import "./style.scss";
// import socialicons from "../../Data/socialicons.json";
import { SOCIAL_ICON } from "../../Data";
import { Tooltip } from "antd";

const Socialicons = () => {
  return (
    <div className="socialicons">
      {SOCIAL_ICON.map((element, index) => (
        <Tooltip placement="top" key={index} title={element.text}>
          <a href={element.link} target="_blank" key={index} rel="noreferrer">
            <img src={element.socialmedia} alt={element.alt} />
          </a>
        </Tooltip>
      ))}
    </div>
  );
};
export default Socialicons;
