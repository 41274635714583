import React from "react";
import { CompanyHeader } from "assets/icons";
import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import Twitter from "../assets/icons/twitter.svg";

import Pintrest from "../assets/icons/Pintrest.png";
import LinkedIn from "../assets/icons/linkedin.png";
import Behance from "../assets/icons/behance.svg";
import ProjectHeader from "../assets/images/projectheader.png";
import RPAServices from "assets/images/RPAServices.svg";
import WebServices from "assets/images/WebServices.png";
import DigitalServices from "assets/images/DigitalServices.svg";
import DesignServices from "assets/images/DesignServices.svg";
import MobileServices from "assets/images/MobileServices.png";
import TOOLS_OF_TRADE from "assets/images/toolsoftradefeatured.svg";
import JAVA from "assets/icons/java.png";
import REACT from "assets/icons/react.png";
import NODE from "assets/icons/node.png";
import SOLIDITY from "assets/icons/solidity.png";
import PYTHON from "assets/icons/python.png";
import ANGULAR from "assets/icons/angular.png";
import BlogHeader from "assets/images/blogsheader.png";

export const BLOG_DATA = {
  header: {
    headerimage: BlogHeader,
    heading: "Blog",
    active_page: "Blog",
    subheadinglink: "/blog",
    alt: "Blog",
  },
};

export const HOMEDATA = {
  technical: [
    {
      image: RPAServices,
      alt: "RPA, AI, Machine Learning",
    },
    {
      image: WebServices,
      alt: "Web Development Services",
    },
    {
      image: MobileServices,
      alt: "Mobile App Development Services",
    },
    {
      image: DesignServices,
      alt: "Designing Services",
    },
    {
      image: DigitalServices,
      alt: "Digital Marketing Services",
    },
  ],
  tools: {
    image: TOOLS_OF_TRADE,
    alt: "Future Proof Technologies Featured Image",
    icons: [
      {
        icon: JAVA,
        alt: "Java",
        link: "https://www.java.com/en/",
      },
      {
        icon: REACT,
        alt: "React",
        link: "https://reactjs.org/docs/getting-started.html",
      },
      {
        icon: PYTHON,
        alt: "Python",
        link: "https://docs.python.org/3/",
      },
      {
        icon: NODE,
        alt: "Node JS",
        link: "https://nodejs.org/en/docs/",
      },
      {
        icon: SOLIDITY,
        alt: "Solidity",
        link: "https://docs.soliditylang.org/en/latest/",
      },
      {
        icon: ANGULAR,
        alt: "Angular",
        link: "https://angular.io/docs",
      },
    ],
  },
};

export const PROJECT_INFO = {
  main: {
    header: {
      headerimage: ProjectHeader,
    },
    banner: {
      main: "The Products We Owned Proudly",
      sub: "Exceptional Digital Products",
      para1:
        "We have talented developers who can easily transform your bold business idea into exceptional digital products. So, if you are searching for a partner that will give wings to your idea by turning it into reality, you are at the right place.",
      para2:
        "At Ellocent Labs IT Solutions Pvt. Ltd., we have a talented pool of developers, designers, testers, and business analysts, who ideate, design, and develop data-driven digital products ready to face business challenges. Besides, we offer a complete suite of services to create a seamless digital masterpiece. So, explore our services and let us be your light on the path of digital product development.",
      para3:
        "We have developed our two prominent products as per today's business time. Our first product is <b>Ello Covid Tracer</b>, and the Second one is <b>OCR( Optical Character Recognition)</b>.",
    },
  },
};

export const SOCIAL_ICON = [
  {
    text: "Follow us on Facebook",
    socialmedia: Facebook,
    link: "https://www.facebook.com/Ellocentlabsitsolutions/",
    alt: "Facebook",
  },
  {
    text: "Follow us on Instagram",
    socialmedia: Instagram,
    link: "https://www.instagram.com/ellocentlabs",
    alt: "instagram",
  },
  {
    text: "Follow us on Twitter",
    socialmedia: Twitter,
    link: "https://twitter.com/ellocent_labs",
    alt: "twitter",
  },
  {
    text: "Follow us on LinkedIn",
    socialmedia: LinkedIn,
    link: "https://in.linkedin.com/company/ellocent-labs-it-solutions-private-limited",
    alt: "linkedin",
  },
  {
    text: "Follow us on Behance",
    socialmedia: Behance,
    link: "https://www.behance.net/ellocentlabs",
    alt: "behance",
  },
  {
    text: "Follow us on Pinterest",
    socialmedia: Pintrest,
    link: "https://in.pinterest.com/ellocent_labs/",
    alt: "Pinterest",
  },
];

export const JOBS_OPENING = [
  {
    ionic: {
      header: {
        heading: "Ionic Developer",
        subheading: "Career",
        pagename: "Ionic Developer",
        image: <CompanyHeader />,
        alt: "career-header-image",
      },
      description:
        "Ellocent Labs IT Solutions Pvt. Ltd. is seeking an experienced Ionic developer with demonstrated experience in designing and implementing enterprise-scale mobile app solutions. The ideal candidate will bring both strategic vision and solid execution skills to create PWA Hybrid Apps using the Ionic Framework along with Firebase integration.",

      list: [
        {
          heading: "Responsibilities",
          list: [
            {
              title:
                "Primary responsibilities to include designing, developing and implementing solutions for Mobile Hybrid App (IONIC)",
            },
            {
              title:
                "Designing and developing well-tested, high performing and scalable cross-platform mobile applications on the Ionic platform for iOS and Android platforms",
            },
            {
              title:
                "Working with Product and UX to understand customer requirements, and collaborating on the design of new features.",
            },
            {
              title:
                "Collaborating with other team members in an agile environment.",
            },
            {
              title:
                "Driving development best practices, including unit testing, coding standards, and code reviews.",
            },
            {
              title:
                "Identifying, proposing and implementing best-fit solutions in a complex business environment",
            },
          ],
        },
        {
          heading: "Requirements",
          list: [
            {
              title:
                "2+ years in design, development, and implementation of hybrid mobile applications (IONIC)",
            },
            {
              title:
                "Stronghold in IONIC, JavaScript (or TypeScript), HTML5, CSS, Cordova and Firebase",
            },
            {
              title: "Strong experience in UX design",
            },
            {
              title:
                "Knowledge of building PWA (Progressive web apps) would be a plus",
            },
          ],
        },
      ],
    },
    wordpress: {
      header: {
        heading: "Wordpress Developer",
        subheading: "Career",
        pagename: "Wordpress Developer",
        headerimage: <CompanyHeader />,
      },
      description:
        "Looking for an experienced WordPress developer with a passion for creating innovative web solutions with proven ideation and innovation skills is preferred.",
      list: [
        {
          heading: "Responsibilities and Duties",
          list: [
            {
              title: "Generating WordPress themes and plugins.",
            },
            {
              title:
                "Demonstrable knowledge of web technologies including HTML, CSS, Javascript, AJAX, etc",
            },
            {
              title: "WordPress theme customization and child themes",
            },
            {
              title: "Creating the website architecture.",
            },
            {
              title:
                "Scope, analyze, design, test, debug and implement complex applications.",
            },
            {
              title:
                "Experience in common third-party APIs (Google, PayPal, Twilio and many others)",
            },
            {
              title:
                "Ensuring high-performance and availability, and managing all technical aspects of the CMS.",
            },
            {
              title: "In-depth knowledge of MySQL database and Oops Concepts",
            },
            {
              title: "Troubleshooting content issues.",
            },
            {
              title: "Monitoring the performance of the live website.",
            },
            {
              title:
                "Basic Knowledge of Magento/ Joomla / Shopify knowledge is plus.",
            },
            {
              title: "Demonstrable knowledge of PHP is a big plus",
            },
          ],
        },
        {
          heading: "Required Experience, Skills and Qualifications",
          list: [
            {
              title:
                "Bachelor’s degree in Computer Science or a similar field.",
            },
            {
              title:
                "Proven work experience as a WordPress Developer, at least 1+ years",
            },
            {
              title:
                "Knowledge of front-end technologies including CSS3, JavaScript, HTML5, and jQuery.",
            },
            {
              title:
                "Knowledge of code versioning tools including Git, Mercurial, and SVN.",
            },
            {
              title:
                "Good understanding of website architecture and aesthetics.",
            },
            {
              title: "Good communication skills.",
            },
            {
              title: "Ability to work independently with minimal guidance",
            },
          ],
        },
      ],
    },
    angular: {
      header: {
        heading: "Angular Developer",
        subheading: "Career",
        pagename: "Angular Developer",
        headerimage: <CompanyHeader />,
      },
      description:
        "Ellocent Labs is looking to hire an experienced Full Stack Developer with strong hands in ReactJS and Angularjs to join our dynamic team. You will be responsible for creating a top-level coding-base using Angular/ React best practices. Your role will require you to implement an exciting and streamlined user experience in the form of a Java-based desktop and mobile web-app.<br><br>Experienced in translating mock-up designs into working UI build using JavaScript Frameworks. Good knowledge of UI/UX is also required so that the looks as well as the functional aspect of the application that you will work on, is of best in class. Ultimately, a top-class AngularJS Developer can design and build a streamlined application to company specifications that perfectly meet the needs of the user.",
      list: [
        {
          heading: "Responsibilities",
          list: [
            {
              title:
                "Designing and developing user interfaces using AngularJS best practices.",
            },
            {
              title:
                "Build Applications with Latest technologies (React, Angular JS)",
            },
            {
              title:
                "Building efficient front-end systems that drive complex web applications, and write clean, fast, compatible, easy-to-use, automated-testable Angular/ React JSX to realize our ideas.",
            },
            {
              title: "Optimize application for maximum speed and scalability",
            },
            {
              title:
                "Assure that all user input is validated before submitting to back-end",
            },
            {
              title: "Developing product analysis tasks.",
            },
            {
              title: "Conducting performance tests.",
            },
            {
              title: "Creating clean code.",
            },
            {
              title:
                "Taking responsibility for both the technical side and the design side of applications",
            },
            {
              title:
                "Work closely with internal team and key external partners to ensure new developments align with the roadmap and integrate seamlessly with other platform components",
            },
            {
              title:
                "Ability to work independently as well as collaborate with a small team of developers on various work streams and guide them on development tasks, perform code reviews, and ensure design and coding are adhering to architectural standards.",
            },
            {
              title:
                "Support ongoing development for existing projects/applications Improve test coverage of existing codebases.",
            },
          ],
        },
        {
          heading: "Skills & Qualifications:",
          list: [
            {
              title:
                "Good understanding of advanced JavaScript libraries and frameworks, such as AngularJS, KnockoutJS, ReactJS",
            },
            {
              title:
                "Production experience with JavaScript-based single page applications and frameworks (Ember, Vue, React, Angular, etc.)",
            },
            {
              title: "Solid modern JavaScript experience (ES6/ECMAScript 2015)",
            },
            {
              title:
                "Ability to match design direction with markup and styling languages (HTML, CSS, SCSS)",
            },
            {
              title:
                "Experience of working other technologies/frameworks like NodeJS, Express",
            },
            {
              title:
                "Experienced in complex AngularJS applications, directives, controllers, services",
            },
            {
              title:
                "Experience building responsive user interfaces that work seamlessly across PCs, Mobiles, Tablets, etc.",
            },
            {
              title:
                "Good understanding of AJAX and JavaScript Dom manipulation Techniques",
            },
            {
              title: "Experience with RESTful Web-services",
            },
            {
              title:
                "Basic understanding of server-side CSS pre-processing platforms, such as LESS and SASS",
            },
            {
              title:
                "Proficient understanding of client-side scripting and JavaScript frameworks, including jQuery",
            },
            {
              title:
                "In-depth understanding of the entire web development process (design, development, and deployment)",
            },
            {
              title:
                "Experience working closely with back-end developers and an understanding of how to best manage interfacing with APIs",
            },
            {
              title: "Experience with source-control practices such as Git",
            },
            {
              title:
                "Experience using JavaScript building tools like Gulp or Grunt.",
            },
            {
              title:
                "Knowledge of JavaScript MV-VM/MVC frameworks including AngluarJS.",
            },
            {
              title:
                "Proven experience implementing front-end and back-end JavaScript applications.",
            },
            {
              title: "Critical thinker and good problem-solver.",
            },
          ],
        },
      ],
    },
    "ui-ux": {
      header: {
        heading: "Experienced UI/UX Designer",
        subheading: "Career",
        pagename: "Experienced UI/UX Designer",
        headerimage: <CompanyHeader />,
      },
      description: "Coming Soon",
      list: [
        {
          heading: "Responsibilities",
          list: [
            {
              title: "Coming Soon",
            },
          ],
        },
        {
          heading: "Skills & Qualifications:",
          list: [
            {
              title: "Coming Soon",
            },
          ],
        },
      ],
    },
  },
];
