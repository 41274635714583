import React from "react";

import "../buttons/style.scss";
import { Button } from "antd";

const ButtonComponent = (props) => {
  const { primaryBtn, ...rest } = props;
  return (
    <Button className="primarybtn" {...rest}>
      {props.primaryBtn}
    </Button>
  );
};

export default ButtonComponent;
