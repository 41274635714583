import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../../locales/en";
import de from "../../locales/de";
import fr from "../../locales/fr";
import nl from "../../locales/nl";

let phoneLang = "en";

export const initI18 = () => {
  i18n.use(initReactI18next).init({
    compatibilityJSON: "v3",
    resources: {
      en: { translation: { ...en } },
      de: { translation: { ...de } },
      fr: { translation: { ...fr } },
      nl: { translation: { ...nl } },
    },
    fallbackLng: ["en"],
    lng: phoneLang,    
  });
};

export default i18n;
