import Experience from "assets/images/experience.svg"
import Projects from "assets/images/projects.svg"
import Professionals from "assets/images/professionals.png"
import Clients from "assets/images/clients.png"
import PlanningIcon from "assets/images/planning.svg"
import SprintIcon from "assets/images/sprint.svg"
import IterationIcon from "assets/images/iteration.svg"
import ReleaseIcon from "assets/images/release.svg"
export default {
  buttons: {
    submit: "Indienen",
  },
  header: {
    home: "Huis",
  },
  navbar: {
    whatwedo: "Wat we doen",
    technologies: "Technologieën",
    industries: "Industrieën",
    portfolio: "Portefeuille",
    company: "Bedrijf",
    contact_us: "Neem contact met ons op",
  },
  "small-navbar-menu": {
    projects: "Projects",
    products: "Products",
    how_we_work: "How We Work",
    career: "Career",
    about_us: "About Us",
  },
  footer: {
    info: "Wij van Ellocent Labs IT Solutions Pvt. Ltd. gebruiken Scrum, het meest populaire Agile Framework in ons softwareontwikkelingsproces. Dit is een iteratieve benadering met als kern de sprint - de scrumterm voor iteratie.",
    term: "voorwaarden",
    privacy: "Privacy",
    help: "Helpen",
    copy_right: "auteursrechten",
    company: "Ellocent Labs IT Solutions Pvt. Ltd.",
    rights: "alle rechten voorbehouden",
    about_company: "About Company",
    quick_links_heading: "Snelle links",
    useful_links_heading: "handige links",
    contact_us: "Reach Us",
    quick_links: [
      {
        name: "Wat we doen",
        link: "/whatwedo",
      },
      {
        name: "Technologieën",
        link: "/technologies",
      },
      {
        name: "Industrieën",
        link: "/industries",
      },
      {
        name: "Portefeuille",
        link: "/projects",
      },
      {
        name: "Leven@ellocent",
        link: "/huis",
      },
    ],
    useful_links: [
      {
        name: "Bedrijf",
        link: "/how-we-work",
      },
      {
        name: "Privacybeleid",
        link: "/privacy-policy",
      },
      {
        name: "algemene voorwaarden",
        link: "/terms",
      },
      {
        name: "Blog",
        link: "/blog",
      },
      {
        name: "Sitemap",
        link: "/sitemap",
      },
    ],
    contact: [
      {
        content:
          "Perceel nr. C-184, 4e verdieping, industriegebied, fase VIIIA Sector 75, Sahibzada Ajit Singh Nagar, Punjab 160071",
        href: "",
      },
      {
        content: "hr@ellocentlabs.com",
        href: "mailto:hr@ellocentlabs.com",
      },
      {
        content: "+91 7499677200",
        href: "tel:+91 7499677200",
      },
    ],
  },
  company_links: [
    { link: "/how-we-work", title: "How We Work" },
    { link: "/career", title: "Career" },
    { link: "/about-us", title: "About Us" },
  ],
  portfolio_links: [
    { link: "/projects", title: "Projects" },
    { link: "/products", title: "Products" },
  ],
  what_we_do_links: [
    {
      title: "Major Services",
      list: [
        {
          title: "Artificial Intelligence",
          link: "/artificialintelligence",
        },
        {
          title: "Machine Learning",
          link: "/machinelearning",
        },
        {
          title: "Big Data",
          link: "/big-data",
        },
        {
          title: "IoT",
          link: "/internet-of-things",
        },
        {
          title: "Cryptocurrency/Blockchain",
          link: "/cryptocurrency-blockchain",
        },
        {
          title: "AWS/Cloud Services",
          link: "/aws-cloud-services",
        },
        {
          title: "Data Science",
          link: "/data-science",
        },
        {
          title: "AWS",
          link: "/amazon-web-services",
        },
        {
          title: "BOT Development",
          link: "/bot-development",
        },
      ],
    },
    {
      title: "Web Development",
      list: [
        {
          title: "Full Stack Development",
          link: "/full-stack-development",
        },
        {
          title: "Backend Development",
          link: "/backend-development",
        },
        {
          title: "CMS Development",
          link: "/cms-development",
        },
        {
          title: "Customer Web Development",
          link: "/custom-web-development",
        },
        {
          title: "Progressive App Development",
          link: "/progressive-app-development",
        },
      ],
    },
    {
      title: "Mobile App Development",
      list: [
        {
          title: "React Native",
          link: "/react-native",
        },
        {
          title: "Kotlin",
          link: "/backend-development",
        },
      ],
    },
    {
      title: "Designing Services",
      list: [
        {
          title: "UI/UX Designing",
          link: "/ui-ux-designing",
        },
        {
          title: "Graphic Designing",
          link: "graphic-designing",
        },
      ],
    },
    {
      title: "Digital Marketing",
      list: [
        {
          title: "Search Engine Optimization",
          link: "/search-engine-optimization",
        },
        {
          title: "Social Media Optimization",
          link: "/social-media-optimization",
        },
        {
          title: "Online Reputation Management",
          link: "/online-reputation-management",
        },
        {
          title: "E-Commerce Marketing Services",
          link: "/e-commerce-marketing-services",
        },
      ],
    },
  ],
  home: {
    highlights: {
      sub: "Our Key Highlights",
      main: "ELIS Record in Nutshell",
      cards: [
        {
          src: Experience,
          number: 3,
          title: "Years<br> Experience",
        },
        {
          src: Projects,
          number: 50,
          title: "Successfully<br> Deliver Projects",
        },
        {
          src: Professionals,
          number: 50,
          title: "Professionals<br> Under One Umbrella",
        },
        {
          src: Clients,
          number: 30,
          title: "Happy<br> Clients",
        },
      ],
    },
    how_we_work: {
      sub: "How We Work",
      main: "Embracing Agile Development Until The Next Methodological Marvel Drops",
      list: [
        {
          icon: PlanningIcon,
          title: "Planning phase",
          description:
            "The product owner defines the scope of their project. The product owner discusses the key requirements with the clients like features and the proposed end results. Additionally, the time estimations and cost of potential projects are also done in this phase.",
        },
        {
          icon: SprintIcon,
          title: "Sprint",
          description:
            "The product owner will pick up the best developers with the necessary tools & resources along with the estimated time of delivering the sprint. The design team will start to design a mock-up of the user interface.",
        },
        {
          icon: IterationIcon,
          title: "Iterations",
          description:
            "This phase tends to be the longest as immense work will be carried out here. The developers will work with the designers to combine all product requirements, and customer feedback turning the design into functionality (coding). This stage is a cornerstone of this cycle and enables developers to create the functionality as per the client’s requirements.",
        },
        {
          icon: ReleaseIcon,
          title: "Release",
          description:
            "The product is ready to launch but the Quality Assurance team needs to fully ensure that the software is fully functional and responsive. On encountering a bug, the developers will address them swiftly. when everything is clear, the product’s final iteration can then be released into production.",
        },
      ],
    },
    awards: {
      sub: "Awards and Accolades",
      main: "Experience our Precision, Quality, & Agility",
    },
    tech_expertise: {
      sub: "Our Technical Expertise",
      main: "We Have Catered Every Industry & Niche",
      button: "Explore More",
      list: [
        {
          title: "Major Services",
          link: "#",
        },
        {
          title: "Web Development Services",
          link: "#",
        },
        {
          title: "Mobile App Development Services",
          link: "#",
        },
        {
          title: "Designing Services",
          link: "#",
        },
        {
          title: "Digital Marketing Services",
          link: "#",
        },
      ],
    },
    video_testimonial: {
      sub: "Trusted By Our Esteemed Clients",
      main: "The Buzz Word",
    },
    proud_work: {
      sub: "Work We Are Proud Of",
      main: "We Are A Whole Package To Spark Growth, & Break Business Boundaries",
      button: "Explore More",
    },
    tools_trade: {
      sub: "Our Tools of Trade",
      main: "We Hold In-Depth Expertise In Future Proof Technologies",
      button: "Explore More",
    },
    testimonial: {
      sub: "WHAT OUR CLIENTS SAY",
      main: "Trusted By Our Esteemed Clients",
    },
    blog: {
      sub: "News and Blog",
      main: "Trending article & stories",
      button: "View All Blog",
    },
    contact_form: {
      sub: "BEREIK ONS UIT",
      main: "Niet te stoppen innovatie begint hier",
    },
  },
  technology: {
    header: {
      main_heading: "Technologies",
      active_page: "Technologies",
    },
    sub: "Technology Stack",
    main: "We Are A Whole Package To Spark Growth",
    description:
      "Ellocent Labs IT Solutions Pvt. Ltd. is an emerging software solution provider company in India. We have a seasoned team of developers, designers, and testers who are chosen for their commitment to quality and exemplary skills. Our developers employ the latest technologies, frameworks, and components to create efficient solutions per today's digital world demands and requirements. To build stellar software, some technologies are best for developing the front end, and some are best for the back end. Well, our seasoned team can direct you to the specific needs of the project. Additionally, we work closely with our precious clients to turn their dreams into reality, integrate their feedback into projects, and develop efficient software solutions.",
    tool_trade: {
      sub: "Our Tools Of Trade",
      main: "We Hold In-Depth Expertise",
    },
  },
  industries: {
    header: {
      main_heading: "Industries",
      active_page: "Industries",
    },
    sub: "Our Industries",
    main: "Industries We Serve",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
  },
  portfolio: {
    products: {
      header: {
        heading: "Products",
        active_page: "Products",
      },
      banner: {
        main: "The Products We Owned Proudly",
        sub: "Exceptional Digital Products",
        para1:
          "We have talented developers who can easily transform your bold business idea into exceptional digital products. So, if you are searching for a partner that will give wings to your idea by turning it into reality, you are at the right place.",
        para2:
          "At Ellocent Labs IT Solutions Pvt. Ltd., we have a talented pool of developers, designers, testers, and business analysts, who ideate, design, and develop data-driven digital products ready to face business challenges. Besides, we offer a complete suite of services to create a seamless digital masterpiece. So, explore our services and let us be your light on the path of digital product development.",
        para3:
          "We have developed our two prominent products as per today's business time. Our first product is <b>Ello Covid Tracer</b>, and the Second one is <b>OCR( Optical Character Recognition)</b>.",
        btn1: "Ello Covid Tracker",
        btn2: "Optical Character Recognition",
      },
      covid_tracer: {
        sub: "Our Product",
        main: "Ello Covid Tracer",
        description:
          "A Tool for Contact Tracing QR Code/ Contactless Check-in for Restaurants, Cafes, Departmental Stores & Hotels Ello COVID Tracer helps in Contactless check-ins during this pandemic COVID-19 situation. Here, you can easily check-in,via keyless entrance system, inside a restaurant, cafe, hotels, etc. without any physical contact. As a guest, you don’t have to queue in a line. This allow customers to scan a QR code at the entrance to check-in and register their contact details. In this way, your customers will get access to a seamless, safe, secured and reliable check-in process. On the other hand, as an admin, the hotel, cafe, restaurant owners will also be highly privileged as it reduces their front desk traffic, saves unnecessary time and expenses spent in the form of employee or physical key making.",
      },
      features: {
        main: "Prominent Features & Benefits",
        featurestitle: "Features",
        benefitstitle: "Benefits",
        featurelist: [
          {
            title: "No more app installation required",
          },
          {
            title: "Easy Customer information collection",
          },
          {
            title: "Fast processing time",
          },
          {
            title: "Interactive User Interface for Restaurant Owners",
          },
          {
            title: "Export Customers data in user friendly format",
          },
          {
            title: "Directly download or print the QR code through Admin panel",
          },
        ],
        benefitlist: [
          {
            title: "Hassle free QR Code scanning",
          },
          {
            title: "Contactless Check-in",
          },
          {
            title:
              "Easy tracking of people during COVID situations as per Government guidelines",
          },
        ],
      },
      covidtimeline: {
        main: "Why Ello COVID Safe?",
        firsttitle: "Quick & Easy",
        firstpara:
          "The year 2020 has been the year of COVID-19 and it has certainly turned out to be the worst year we have experienced as a generation. Every activity around the world has been hit immensely including sports, hotel businesses, restaurants, cafes, and several other small and big ventures. To execute all these activities today in a smooth way demands various government protocols like contact tracing, to be followed strictly. To make this possible, Ello COVID has come up with an efficient way of contact tracing that will help all these enterprises blossom again. Ello COVID makes contact tracing quick and easy by saving plenty of time. It takes care of the complete process right from start to finish.",
        secondtitle: "Safe & Simple",
        secondpara:
          "Ensuring safety has become a priority before going out these days. Stepping out either to play your favorite sport or to visit a restaurant or cafe of your choice has become a little different today. Ello COVID is determined to keep it safe and simple for you. Features like paperless and contactless sign-in along with accurate and fast response keep it simple yet effective. It also allows you to use your mobile’s web browser which is the safest way to distance yourself from any other contact.",
        thirdtitle: "Budget Friendly",
        thirdpara:
          "COVID is playing tough on us and has made many of us financially unstable. Considering this, the budget-friendly COVID tracing tool at Ello COVID plays a crucial role in providing a cost-effective solution that saves the administration time and does not bother you to think much of the money aspect.",
        fourthtitle: "Peace of Mind",
        fourthpara:
          "The satisfaction of knowing that you have been fully covered with all your needs is unmatchable. This is exactly what Ello COVID does to bring you the peace of mind. It adheres to all the new government regulations to keep your customer and business member’s safe. The priority here is to keep your club’s/association’s/business’s data secure. All your information is protected using highly advanced technology along with some concrete security measures.",
        fifthtitle: "Easy Acces",
        fifthpara:
          "For any service to work efficiently, it is important that it provides hassle-free and easy access to its users. Here at Ello COVID, your club’s/businesses authorized administrator will have access to all the records anytime, anywhere at his fingertips. The entire information is saved in one location and can be shared with the relevant state health authorities if and when needed. This makes it even smooth in keeping the communities connected.",
        sixthtitle: "Captures Real-Time Data",
        sixthpara:
          "For all the contact tracings done; the tool captures real-time data and provides a precise quick response. Every sign-in form is time-stamped which is a must-have for an accurate contact tracing. Real-time data helps in achieving desired results and also proves valuable in maintaining correct records.",
      },
      steps: {
        sub: "Our Product",
        main: "So How Does It Work?",
      },
      ocr: {
        sub: "Our Product",
        main: "Why OCR ?",
        description:
          "Suppose you wanted to digitize an article or a printed contract. You’ll spend hours retyping then correcting misprints. In several minutes, you’ll convert all the required material into digital format, employing a scanner (or a digital camera) and Optical Character Recognition software.",
      },
      ocr_timeline: {
        main: "Why Optical Character Recognition?",
        firsttitle: "Solution For OCR",
        firstpara:
          "The exact mechanisms that allow humans to acknowledge objects are yet to be understood. Integrity, purposefulness, adaptableness (IPA) is the three fundamental principles. Scientists document these three principles.<br> These principles constitute the core of OCR, allowing it to duplicate natural or human-like recognition.<br> Let’s take a glance at how OCR recognizes text. First, the program analyzes the structure of the document image. It divides the page into elements like blocks of texts, tables, images, and lines divided into words then – into characters. The program compares them with a group of pattern images, once the characters singled out. It advances numerous hypotheses about what this character is. Based on these hypotheses, the program analyzes different variants of breaking of lines into words and words into aspects. After processing a vast number of probabilistic predictions, the program finally takes the choice, presenting you with the recognized text.<br> A perfect solution to convert text and pictures from your scanned PDF document into the editable Excel format. Converted documents look precisely just like the original – tables, columns, and graphics.",
        secondtitle: "Behind OCR, Technology Lies",
        secondpara:
          "We can convert the differing types of documents, like scanned paper documents, PDF files, or images captured by a camera into editable and searchable data with the help of the technology named OCR.<br> Imagine you’ve got a paper document – for instance, an article, brochure, or PDF contract your partner sent to you by email. A scanner isn’t enough to form this information available for editing, say in Microsoft Word. All a scanner can do is create a picture or a snapshot of the document that’s nothing quite a set of black and white or color dots, referred to as a raster image. To extract and repurpose data from scanned documents, camera images, or image-only PDFs, you would like OCR software that might single out letters on the model. Put them into words then – words into sentences, thus enabling you to access and edit the content of the first document.",
        thirdtitle: "Recognition Of Image Which Captured By Digital Camera",
        thirdpara:
          "Images captured by a camera differ from scanned documents or image-only PDFs. They often have defects like distortion at the sides and dimmed light, making it difficult for many OCR applications to recognize the text correctly. The newest version of OCR supports adaptive recognition technology specifically designed for processing camera images. It offers a variety of features to enhance such images’ standards, providing you with the power to completely use your digital devices’ capabilities.",
        fourthtitle: "Way To Use OCR Software",
        fourthpara:
          "Using OCR is unchallenging: the method generally consists of three stages: Open (Scan) the document, Recognize it then Save during a convenient format (DOC, RTF, XLS, PDF, HTML, TXT) or export data on to at least one among Office applications like Google Drive, CSV, Excel.<br> In the starting, there are the options to Sign up through Gmail and Google too. Here is the option of forgot password. We can recover the password as many times as we want. The way this works is two ways.<br><br> <b>First</b>, the document, whether PDF, image, it will be scanned and will be downloaded automatically, without our permission.<br> <br> <b>Secondly</b>, whether the document is in the image or PDF format Will be scanned, and the result will show in the tabular and section form. Here we have three options to download the report, i.e., Google Drive, CSV, Excel format. Here the download of the document depends upon us whether we want to download the file or not. If we wish to, then we can download the file else we can skip this option of download.<br> Here are other beautiful features of this OCR is Area Scan. In the area scan, we can select the text portion to get data by scanning that specific portion of the document. Here we can choose the multiple or single part of the material. We can scan the barcode and signature, too, and can save in the proper document format.",
        fifthtitle: "Benefits",
        fifthpara:
          "With OCR, the recognized document looks a bit like the first. Advanced, powerful OCR software allows you to save lots of tons of your time and energy when creating, processing, and repurposing various documents. With OCR, you’ll scan paper documents for further editing and sharing with your colleagues and partners. You’ll extract quotes from books and magazines and use them for creating your course studies and papers without the necessity of retyping. With a camera and OCR, you’ll capture text outdoors from banners, posters, and timetables then use the obtained information for your purposes. Within the same way, you’ll capture data from paper documents and books – for instance, if there’s no scanner close at hand. Otherwise, you cannot use it. Additionally, you’ll use OCR software for creating searchable PDF archives.",
        sixthtitle: "Conclusion",
        sixthpara:
          "The entire process of knowledge conversion from an original paper document, image, or PDF takes but a moment. Therefore the final recognized report looks a bit like the original!",
      },
    },
    projects: {
      header: {
        heading: "Projects",
        active_page: "Projects",
      },
    },
  },
};
