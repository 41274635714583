/** external deps */
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Drawer, Layout, Menu, Row } from "antd";
/** internal deps */
import "../navigation/style.scss";
import { MobileNavbar } from "./mobileNavbar";
import { Close, Hamburger } from "assets/icons";

const { Header } = Layout;

export const Navbar = (props) => {
  // drawer is hidden by default
  const [selected, setSelected] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const pathname = props.location?.pathname; //pathname always show undefined

  const toggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [props, pathname]);

  const handleSelection = async (selectedList) => {
    setSelected(selectedList);
  };

  const renderMenu = (element, index) => (
    <Link to={element.path} key={index} rel="canonical"  >
      <Menu.Item
        className={`${selected === element.label && "selected-list"
          } nav-menu-list`}
        onClick={() => {
          handleSelection(element.label);
        }}
      >
        <div className="menu-content">{element.label} </div>
      </Menu.Item>
    </Link>
  );

  return (
    <div id="nav-bar">
      <div className="layout container">
        <Header>
          <div className="logo">
            <Link to="/" rel="canonical">

              <img src={props.logo} alt="logo" />
            </Link>
          </div>
          {/* Desktop Navbar */}
          <div className="desktop-navigation">
            <Menu
              className="menu"
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={["1"]}
            >
              <Row>{props.list.map(renderMenu)}</Row>
            </Menu>
          </div>
          {/* Mobile Navbar */}
          <div className="mobile-navigation">
            <div className="mobilelanguage">
              <div onClick={toggle}>
                {isDrawerOpen ? <Close /> : <Hamburger />}
              </div>
            </div>
            <Drawer
              open={isDrawerOpen}
              onClose={toggle}
              placement="right"
              width="100%"
            >
              <MobileNavbar {...props} />
            </Drawer>
          </div>
        </Header>
      </div>
    </div>
  );
};
