import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import React from "react";
import Layout from "../layout";

const WrapPageElement = ({ element, ...props }) => {
  return (
    <ErrorBoundary>
      <Layout {...props}>{element}</Layout>
    </ErrorBoundary>
  );
};

export default WrapPageElement;
