import React from "react";
// import { Link } from "gatsby";
import "./style.scss";
import { Col, Layout, Row, BackTop } from "antd";
import Socialicons from "./Socialicons";
import { useTranslation } from "react-i18next";
import ContactUs from "./contact-us";
import FooterLinks from "./footerLink";
import { BacktoTop } from "assets/icons";

const { Footer } = Layout;

const currentYear = new Date().getFullYear();

export const FooterSection = () => {
  //
  const { t } = useTranslation();

  return (
    <div className="footer">
      <Layout>
        <Footer>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={9}
              xxl={9}
              className="footer-col"
            >
              <Row>
                <Col xl={20} xxl={20}>
                  <h3>{t("footer.about_company")}</h3>
                  <p>{t("footer.info")}</p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={4}
              xxl={4}
              className="footer-col"
            >
              <h3>{t("footer.quick_links_heading")}</h3>
              <FooterLinks path="footer.quick_links" />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={4}
              xxl={5}
              className="footer-col"
            >
              <h3>{t("footer.useful_links_heading")}</h3>
              <FooterLinks path="footer.useful_links" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={6}>
              <h3>{t("footer.contact_us")}</h3>
              <ContactUs />
            </Col>
          </Row>
          {/* <div className="border"></div> */}
        </Footer>
      </Layout>
      <div className="copyright">
        <Row justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Socialicons />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <p>
              {t("footer.copy_right")}{" "}
              <span>
                &copy; <span>{currentYear}</span> &nbsp;
                <a href="/">{t("footer.company")} </a>
              </span>{" "}
              {t("footer.rights")}
            </p>
          </Col>
          {/* <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="cpy-ryt-section"
          >
            <p>
              <Link to="/privacy-policy">{t("footer.privacy")}</Link> |{" "}
              <Link to="/terms">Terms</Link> |{" "}
              <Link to="/help">{t("footer.help")}</Link>{" "}
            </p>
          </Col> */}
        </Row>
      </div>

      <BackTop>
        <BacktoTop svgClass="upbtn" />
      </BackTop>
    </div>
  );
};
