import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import React from "react";

export const SimplePopoverBody = ({ hide, type }) => {
  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const popoverList =
    type === "company"
      ? t("company_links", { returnObjects: true })
      : t("portfolio_links", { returnObjects: true });

  const renderList = (item, index) => (
    <Link to={item.link} key={index} onClick={hide} rel="canonical">
      <p>{item.title}</p>
    </Link>
  );

  return <div>{popoverList.map(renderList)}</div>;
};

export const MegaMenuBody = ({ hide }) => {
  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";
  const popoverList = t("what_we_do_links", { returnObjects: true });

  const renderSubmenu = (item, index) => (
    <Link to={item.link} key={index} onClick={hide} rel="canonical">
      <p>{item.title}</p>
    </Link>
  );
  const renderList = (item, index) => (
    <div className="grid-col" key={index} onClick={hide}>
      <Link to={item.title.link} rel="canonical">
        <h4>{item.title.title}</h4>
      </Link>
      {item.list.map(renderSubmenu)}
    </div>
  );
  return (
    <div className="mega-menu">
      <div className="gridd">{popoverList.map(renderList)}</div>
    </div>
  );
};
