import Experience from "assets/images/experience.svg";
import Designing from "assets/images/UI_UX.jpg";
import DigitalMarketing from "assets/images/digital_marketing.jpg";
import Digital_ST from "assets/images/Digital_st.png";
import Hybrid_ST from "assets/images/hybrid_ST.png";
import Web_ST from "assets/images/Web_ST.png";
import Major_ST from "assets/images/Major_ST.png";
import Design_ST from "assets/images/Design_st.png";
import MobileDevelopment from "assets/images/MobileDevelopment.jpg";
import Projects from "assets/images/projects.svg";
import Professionals from "assets/images/professionals.png";
import Clients from "assets/images/clients.png";
import Slider1 from "assets/images/slider-1.mp4";
import Slider2 from "assets/images/slider-2.mp4";
import Slider3 from "assets/images/slider-3.jpg";
import PlanningIcon from "assets/images/planning.svg";
import SprintIcon from "assets/images/sprint.svg";
import IterationIcon from "assets/images/iteration.svg";
import ReleaseIcon from "assets/images/release.svg";
import AchieversAward from "assets/images/AchieversAward.png";
import WebExcellence from "assets/images/WebExcellence.png";
import SoftwareCompany from "assets/images/SoftwareCompany.png";
import Clutch from "assets/images/Clutch.png";
import BlueCircleIcon from "assets/icons/blue-circle.svg";
import CoinhavenLogo from "assets/images/coinhaven.png";
import TolobiLogo from "assets/images/tolobi.png";
import BlackhedgeLogo from "assets/images/blackhedge.png";
import Sabur from "assets/images/sabur.png";
import Danish from "assets/images/danish.svg";
import Mark from "assets/images/mark.jpg";
import Boulyn from "assets/images/boulyn.png";
import Shivani from "assets/images/shivani.jpg";
import Zohaib from "assets/images/zohaib.png";
import Healthcare from "assets/images/healthcare.png";
import RealEstate from "assets/images/real-estate.png";
import Travel from "assets/images/travel.png";
import Trading from "assets/images/trading.png";
import Music from "assets/images/music.png";
import Bitcoin from "assets/images/bitcoin.png";
import BitcoinImg from "assets/images/bitcoin2.png";
import Airlines from "assets/images/airlines.png";
import Bigdata from "assets/images/big-data.png";
import Blockchain from "assets/images/blockchain.png";
import Bot from "assets/images/bot.png";
import ECommerce from "assets/images/ecommerce.png";
import ELearning from "assets/images/elearning.png";
import Entertainment from "assets/images/entertainment.png";
import Informational from "assets/images/informational.png";
import RideBooking from "assets/images/ride.png";
import QRCode from "assets/images/qr-code.png";
import IOT from "assets/images/iot.png";
import FoodOrder from "assets/images/order.png";
import DevelopmentIcon from "assets/images/development.svg";
import UXIcon from "assets/icons/ux.svg";
import VectorIcon from "assets/images/vector.svg";
import CodingIcon from "assets/images/coding.svg";
import UserIcon from "assets/icons/user.svg";
import MapIcon from "assets/icons/map.svg";
import ArrowIcon from "assets/icons/arrow.svg";
import LocationIcon from "assets/icons/location.svg";
import PhoneIcon from "assets/icons/phone.svg";
import EmailIcon from "assets/icons/email.svg";
import Code from "assets/images/code.png";
import PerformanceImg from "assets/images/performance.png";
import Loading from "assets/images/loading.png";
import Community from "assets/images/community.png";
import One from "assets/icons/one.svg";
import Two from "assets/icons/two.svg";
import Three from "assets/icons/three.svg";
import Four from "assets/icons/four.svg";
import Five from "assets/icons/five.svg";
import Six from "assets/icons/six.svg";
import CVision from "assets/images/cvision.png";
import NLP from "assets/images/nlp.png";
import TimeSeries from "assets/images/timeseries.png";
import DataAnalytics from "assets/images/data-analytics.png";
import DataLearing from "assets/images/data-learning.png";
import SpeechRecog from "assets/images/speech-recognition.png";
import IndustryHeader from "assets/images/industry-header.png";
import UIIcon from "assets/icons/ui.svg";
import UserPersona from "assets/images/userPersona.svg";
import UserResearch from "assets/images/userResearch.svg";
import RequirementGathering from "assets/images/requirementGathering.svg";
import VisualDesign from "assets/images/visualDesigns.svg";
import Wireframe from "assets/images/wireframes.svg";
import Prototype from "assets/images/prototypes.svg";
import Testing from "assets/images/testing.svg";
import CareerHeader from "assets/images/career-header.svg";
import AboutHeader from "assets/images/aboutus-header.svg";
import AboutBanner from "assets/images/aboutus-banner.svg";
import AIHeader from "assets/images/ai-header.png";
import AIBanner from "assets/images/aibanner.png";
import AWSHeader from "assets/images/aws-cloud-header.svg";
import AWSBanner from "assets/images/aws-cloud-baner.png";
import BackendHeader from "assets/images/backendheader.png";
import BackendBanner from "assets/images/backendbanner.svg";
import BackendBenefits from "assets/images/backendbenefits.svg";
import BigDataBanner from "assets/images/bigdatabanner.svg";
import BigDataHeader from "assets/images/bigdataheader.svg";
import BotHeader from "assets/images/botheader.svg";
import BotBanner from "assets/images/botbanner.svg";
import BotSecondBanner from "assets/images/botpurple.svg";
import ChooseBotBanner from "assets/images/bulbvector.svg";
import CMSHeader from "assets/images/cmsheader.png";
import CMSBanner from "assets/images/cmsbanner.svg";
import CMSSolution from "assets/images/cmssolution.png";
import CryptoHeader from "assets/images/cryptoheader.png";
import CryptoBanner from "assets/images/cryptobanner.svg";
import ProgressHeader from "assets/images/progressiveheader.png";
import ProgressBanner from "assets/images/progressivebanner.svg";
import CustomerHeader from "assets/images/customerheader.svg";
import CustomerBanner from "assets/images/customerbanner.svg";
import DataScHeader from "assets/images/datascienceheader.png";
import DataScBanner from "assets/images/datasciencebanner.svg";
import DataScVector from "assets/images/datasciencevector.svg";
import ECommerceHeader from "assets/images/ecommerceheader.svg";
import ECommerceBanner from "assets/images/ecommercebanner.svg";
import ECommerceServices from "assets/images/ecommerceservices.svg";
import ECommerceHiring from "assets/images/ecommercehiring.svg";
import FullStackBanner from "assets/images/fullstackbanner.png";
import FullStackHeader from "assets/images/fullstackheader.png";
import FullStackBenefits from "assets/images/fullstackbenefits.svg";
import GraphicHeader from "assets/images/graphicheader.svg";
import GraphicBanner from "assets/images/graphicbanner.svg";
import HelpHeader from "assets/images/help-header.svg";
import HowweworkHeader from "assets/images/howweworkheader.svg";
import HowweworkBanner from "assets/images/howweworkbanner.svg";
import IOTHeader from "assets/images/iotheader.svg";
import IOTBanner from "assets/images/iotbanner.png";
import kotlinHeader from "assets/images/kotlin-header.svg";
import kotlinBanner from "assets/images/kotlin-banner.svg";
import KotlinChoose from "assets/images/kotlin-mobile.svg";
import KotlinVector from "assets/images/kotlin-vector.png";
import MLHeader from "assets/images/mlheader.png";
import MLBanner from "assets/images/mlbanner.svg";
import MLOffer from "assets/images/whatweoffer.svg";
import ORMHeader from "assets/images/ormheader.svg";
import ORMBanner from "assets/images/ormbanner.png";
import PrivacyHeader from "assets/images/privacyheader.png";
import ProductHeader from "assets/images/productheader.svg";
import QRBanner from "assets/images/qrbanner.jpg";
import OCRBanner from "assets/images/ocrbanner.jpg";
import ReactHeader from "assets/images/reactheader.png";
import ReactBanner from "assets/images/reactbanner.png";
import SEOHeader from "assets/images/seoheader.svg";
import SEOBanner from "assets/images/seobanner.svg";
import SEOHiring from "assets/images/seohire.svg";
import SMOHeader from "assets/images/smoheader.svg";
import SMOBanner from "assets/images/smobanner.png";
import SMOHiring from "assets/images/smohire.svg";
import TechBanner from "assets/images/techbanner.svg";
import UIHeader from "assets/images/uiheader.png";
import UIBanner from "assets/images/uibanner.png";
import bubble from "assets/icons/bubble.svg";
import webflow from "assets/icons/webflow.svg";
import ToolsOfTrade from "assets/images/toolsoftrade.jpg";
import ContactHeader from "assets/images/contact-header.svg";
import Idea from "assets/images/idea.png";
import Brainstorm from "assets/images/brainstrom.png";
import PC from "assets/images/pc.png";
import Success from "assets/images/success.png";
import Research from "assets/images/research.png";
import Feedback from "assets/images/feedback.png";
import Shield from "assets/images/shield.png";
import DocumentImage from "assets/images/document.png";
import Board from "assets/images/board.png";
import Audit from "assets/images/audit.png";
import Facebook from "assets/images/facebook-img.png";
import Instagram from "assets/images/instagram-img.png";
import Twitter from "assets/images/twitter-img.png";
import Reputation from "assets/images/reputation.png";
import Promotion from "assets/images/promote.png";
import WebSetting from "assets/images/web-settings.png";
import ContentCreation from "assets/images/content-creator.png";
import JavascriptImg from "assets/images/javascript.png";
import CSSImg from "assets/images/css.png";
import HTMLImg from "assets/images/html.png";
import Significant from "assets/images/significant.png";
import Wordpress from "assets/images/wordpress.svg";
import Vision from "assets/images/vision.svg";
import CMSPlugins from "assets/images/cms-plugins.svg";
import CustomTheme from "assets/images/custom-theme.svg";
import CMSServices from "assets/images/cms-development-services.svg";
import CustomerReview from "assets/images/customer-review.png";
import Agile from "assets/images/agile.png";
import QualityCode from "assets/images/qualitycode.png";
import Secure from "assets/images/secure.png";
import Smartwatch from "assets/images/smart-watch.svg";
import Smartphone from "assets/images/smartphone.svg";
import HomeAutomation from "assets/images/home-automation.svg";
import HealthcareImg from "assets/images/healthcare.svg";
import Sale from "assets/images/point-of-sale.svg";
import DataPre from "assets/images/datapre.svg";
import GatheringData from "assets/images/gathering-data.svg";
import Training from "assets/images/training.svg";
import Evaluation from "assets/images/evaluation.svg";
import ResearchImg from "assets/images/research.svg";
import Bluedot from "assets/icons/bluedot.svg";
import Yellowdot from "assets/icons/yellowdot.svg";
import Purpledot from "assets/icons/purpledot.svg";
import Pinkdot from "assets/icons/pinkdot.svg";
import Liarrow from "assets/icons/liarrow.svg";
import TickIcon from "assets/icons/tick.svg";
import Figma from "assets/images/figma.png";
import AdobeAI from "assets/images/ai.png";
import AdobeXD from "assets/images/xd.png";
import Sketch from "assets/images/sketch.png";
import Zaplin from "assets/images/zaplin.png";
import Invision from "assets/images/invision.png";
import Coreldraw from "assets/images/coreldraw.png";
import Photoshop from "assets/images/photoshop.png";
import ALogo from "assets/icons/a.svg";
import SkypeLogo from "assets/icons/skype.svg";
import Notepad from "assets/icons/note.svg";
import Mobile from "assets/icons/mobile.svg";
import VectorLogo from "assets/icons/vector.svg";
import GitLogo from "assets/icons/git.svg";
import PlayButton from "assets/icons/play-button.svg";
import Communication from "assets/images/seocommunication.png";
import Auditing from "assets/images/seoauditing.png";
import Business from "assets/images/seobusiness.png";
import Digital from "assets/images/seodigital.png";
import Flexibility from "assets/images/seoflexibility.png";
import QualityWork from "assets/images/seoqualitywork.png";
import Start from "assets/images/seostart.png";
import Tracking from "assets/images/seotracking.png";
import Transparency from "assets/images/seotransparency.png";
import ONE_ICON from "assets/icons/01.svg";
import TWO_ICON from "assets/icons/02.svg";
import THREE_ICON from "assets/icons/03.svg";
import FOUR_ICON from "assets/icons/04.svg";
import FIVE_ICON from "assets/icons/05.svg";
import SIX_ICON from "assets/icons/06.svg";
import BubbleBanner from "assets/images/BubbleBanner.png";
import BubbleHeader from "assets/images/BubbleHeader.png";
import BubbleQuick from "assets/images/BubbleQuick.png";
import BubbleInvest from "assets/images/BubbleInvest.png";
import speedometer from "assets/icons/speedometer.png";
import scalability from "assets/icons/scalability.png";
import economic from "assets/icons/economic.png";
import Webpage from "assets/icons/webpage.png";
import GurbaniUpdesh from "assets/icons/gurbaniupdesh.png";
// import Cryptoleagues from "assets/icons/cryptoleagues.png";
import ZappTaxi from "assets/icons/zapptaxi.png";
import CoinHaven from "assets/icons/coinhaven.svg";

export default {
  buttons: {
    submit: "Submit",
    postComment: " Post Comment",
  },
  header: {
    home: "Home",
  },
  navbar: {
    whatwedo: "Services",
    technologies: "Technologies",
    industries: "Industries",
    portfolio: "Portfolio",
    blog: "Blog",
    company: "Company",
    contact_us: "Contact Us",
  },
  "small-navbar-menu": {
    projects: "Projects",
    products: "Products",
    how_we_work: "How We Work",
    career: "Career",
    about_us: "About Us",
  },
  footer: {
    info: "We at Ellocent Labs IT Solutions Pvt. Ltd. use Scrum, the most popular Agile Framework in our software development process. This is an iterative approach that has at its core the sprint — the scrum term for iteration.",
    privacy: "Privacy Policy",
    help: "Help",
    copy_right: "Copyright",
    company: "Ellocent Labs IT Solutions Pvt. Ltd.",
    rights: "All Rights Reserved",
    about_company: "About Company",
    quick_links_heading: "Quick Links",
    useful_links_heading: "Useful Links",
    contact_us: "Reach Us",
    quick_links: [
      {
        name: "About Us",
        link: "/about-us",
      },
      {
        name: "How We Work",
        link: "/how-we-work",
      },
      {
        name: "Projects",
        link: "/projects",
      },
      {
        name: "Products",
        link: "/products",
      },
    ],
    useful_links: [
      // {
      //   name: "Terms & Condition",
      //   link: "/terms",
      // },
      // {
      //   name: "Privacy Policy",
      //   link: "/privacypolicy",
      // },
      {
        name: "Contact Us",
        link: "/contact-us",
      },
      // {
      //   name: "Help",
      //   link: "/help",
      // },
    ],
    contact: [
      {
        content:
          "Plot No. C-184, 4th Floor, Industrial Area, Phase VIIIA Sector 75, Sahibzada Ajit Singh Nagar, Punjab 160071",
        href: "https://www.google.com/maps/place/Ellocent+Labs+IT+Solutions+Pvt.+Ltd./@30.7031862,76.6902082,17z/data=!3m1!4b1!4m5!3m4!1s0x390fef8e59f841c1:0xade0c251c2db47d7!8m2!3d30.7031816!4d76.6923969",
        target: "_blank",
      },
      {
        content:
          "Ellocent Labs IT Solutions Private Limited 7700 Hurontario St #503 #2315 Brampton, ON L6Y4M3 Canada",
        href: "",
        target: "_blank",
      },
      {
        content: "hr@ellocentlabs.com",
        href: "mailto:hr@ellocentlabs.com",
      },
      {
        content: "+91 7499677200",
        href: "tel:+91 7499677200",
      },
    ],
  },
  company_links: [
    { link: "/how-we-work", title: "How We Work" },
    { link: "/career", title: "Career" },
    { link: "/about-us", title: "About Us" },
  ],
  portfolio_links: [
    { link: "/projects", title: "Projects" },
    { link: "/products", title: "Products" },
  ],
  what_we_do_links: [
    {
      title: {
        title: "Major Services",
        link: "/major-services",
      },
      list: [
        {
          title: "Artificial Intelligence",
          link: "/artificial-intelligence",
        },
        {
          title: "Machine Learning",
          link: "/machine-learning",
        },
        {
          title: "Big Data",
          link: "/big-data",
        },
        {
          title: "IoT",
          link: "/internet-of-things",
        },
        {
          title: "Cryptocurrency",
          link: "/cryptocurrency",
        },
        {
          title: "AWS/Cloud Services",
          link: "/aws-cloud-services",
        },
        {
          title: "Data Science",
          link: "/data-science",
        },
        {
          title: "BOT Development",
          link: "/bot-development",
        },
      ],
    },
    {
      title: {
        title: "Web Development",
        link: "/web-development",
      },
      list: [
        {
          title: "Full Stack Development",
          link: "/full-stack-development",
        },
        {
          title: "Backend Development",
          link: "/backend-development",
        },
        {
          title: "CMS Development",
          link: "/cms-development",
        },
        {
          title: "Custom Web Development",
          link: "/custom-web-development",
        },
        {
          title: "Progressive App Development",
          link: "/progressive-app-development",
        },
        {
          title: "Bubble",
          link: "/bubble",
        },
      ],
    },
    {
      title: {
        title: "Hybrid App Development",
        link: "/mobile-app-development",
      },
      list: [
        {
          title: "React Native",
          link: "/react-native",
        },
        {
          title: "Kotlin",
          link: "/kotlin",
        },
      ],
    },
    {
      title: {
        title: "Designing Services",
        link: "/designing-services",
      },
      list: [
        {
          title: "UI/UX Designing",
          link: "/ui-ux-designing",
        },
        {
          title: "Graphic Designing",
          link: "/graphic-designing",
        },
      ],
    },
    {
      title: {
        title: "Digital Marketing ",
        link: "/digital-marketing",
      },
      list: [
        {
          title: "Search Engine Optimization",
          link: "/search-engine-optimization",
        },
        {
          title: "Social Media Optimization",
          link: "/social-media-optimization",
        },
        {
          title: "Online Reputation Management",
          link: "/online-reputation-management",
        },
        // {
        //   title: "E-Commerce Marketing Services",
        //   link: "/e-commerce-marketing-services",
        // },
      ],
    },
  ],
  home: {
    slider: [
      {
        sliderimage: Slider1,
        slideralt: "Slider Gif",
        subtitle: "Delight your Customers",
        title: "Unleash Your Idea With Our",
        title2: "Innovative Agile Tech Team",
        description:
          "<b>Ellocent Labs IT Solutions Pvt. Ltd.</b> successfully marked its presence in the prominent <b>IT development</b> business world by delivering innovative solutions via implementing the <b>SOLID</b> coding principles.",
        button1: "Watch Video",
        link1: "#videotestimonials",
        button2: "Learn More",
        link2: "/about-us",
      },
      {
        sliderimage: Slider2,
        slideralt: "Slider Gif",
        subtitle: "Delight your Customers",
        title: "World Changers To Rock The",
        title2: "Digital World",
        title3: "Building Software's For",
        description:
          "<b>Ellocent Labs IT Solutions Pvt. Ltd.</b> successfully marked its presence in the prominent <b>IT development</b> business world by delivering innovative solutions via implementing the <b>SOLID</b> coding principles.",
        button1: "Watch Video",
        link1: "#videotestimonials",
        button2: "Learn More",
        link2: "/about-us",
      },
      {
        sliderimage: Slider3,
        slideralt: "Slider",
        subtitle: "Delight your Customers",
        title: "12th Position",
        title2: "by the Top Software Companies.",
        title3: "We are awarded ",
        description:
          "<b>Ellocent Labs IT Solutions Pvt. Ltd.</b> successfully marked its presence in the prominent <b>IT development</b> business world by delivering innovative solutions via implementing the <b>SOLID</b> coding principles.",
        button2: "Learn More",
        link2: "/about-us",
      },
    ],
    highlights: {
      sub: "Our Key Highlights",
      main: "ELIS Record in Nutshell",
      cards: [
        {
          src: "/experience.svg",
          number: 5,
          title: "Years<br> Experience",
          alt: "5 Years Experience",
        },
        {
          src: Projects,
          number: 200,
          title: "Successfully<br> Delivering Projects",
          alt: "Projects",
        },
        {
          src: Professionals,
          number: 50,
          title: "Professionals<br> Under One Umbrella",
          alt: "Professionals",
        },
        {
          src: Clients,
          number: 80,
          title: "Happy<br> Clients",
          alt: "Happy Clients",
        },
      ],
    },
    how_we_work: {
      sub: "How We Work",
      main: "Embracing Agile Development Until The Next Methodological Marvel Drops",
      list: [
        {
          icon: PlanningIcon,
          alt: "Planning phase",
          title: "Planning phase",
          description:
            "The product owner defines the scope of the project. The product owner discuss the key requirements with the clients like features and the proposed end results. Additionally, the time estimations and cost of potential projects are also done in this phase.",
        },
        {
          icon: SprintIcon,
          title: "Sprint",
          alt: "Sprint",
          description:
            "The product owner will pick up the best developers with the necessary tools & resources along with the estimated time of delivering the sprint. The design team will start to design a mock-up of the user interface.",
        },
        {
          icon: IterationIcon,
          title: "Iterations",
          alt: "Iterations",
          description:
            "This phase tends to be the longest as immense work will be carried out here. The developers will work with the designers to combine all product requirements, and customer feedback turning the design into functionality (coding). This stage is a cornerstone of this cycle and enables developers to create the functionality as per the client’s requirements.",
        },
        {
          icon: ReleaseIcon,
          title: "Release",
          alt: "Release",
          description:
            "The product is ready to launch but the Quality Assurance team needs to fully ensure that the software is fully functional and responsive. On encountering a bug, the developers will address them swiftly. when everything is clear, the product’s final iteration can then be released into production.",
        },
      ],
    },
    awards: {
      sub: "Awards and Accolades",
      main: "Experience our Precision, Quality & Agility",
      cards: [
        {
          src: WebExcellence,
          title:
            "<b>Web Excellence Award</b><br/><small>Our project <b>“Cryptoleagues”</b> bagged <b>“Web Excellence Award”,</b> in May 2022</small>",
          alt: "Trophy Icon",
        },
        {
          src: SoftwareCompany,
          title:
            "<b>12th Position!</b><br/><small>Successfully secured the <b>12th Position</b> among top <b>Software Development Companies</b> in April 2022</small>",
          alt: "Trophy Icon",
        },
        {
          src: AchieversAward,
          title:
            "<b>Emerging company!</b><br/><small>Won the <b>“Emerging company”</b> award by IAA in December 2021</small>",
          alt: "Trophy Icon",
        },
        {
          src: Clutch,
          title:
            "<b>8th Spot!</b><br/><small>Bagged the <b>8th Spot</b> in the list of <b>Top Software Development Companies</b> in August 2021</small>",
          alt: "Trophy Icon",
        },
      ],
    },
    tech_expertise: {
      sub: "Our Multi Vertical Expertise",
      main: "We Have Catered Every Industry & Niche",
      button: "Explore More",
      icon: BlueCircleIcon,
      alt: "",
      list: [
        {
          title: "RPA, AI, Machine Learning",
        },
        {
          title: "Web Development Services",
        },
        {
          title: "Mobile App Development Services",
        },
        {
          title: "Designing Services",
        },
        {
          title: "Digital Marketing Services",
        },
      ],
    },
    video_testimonial: {
      sub: "Trusted By Our Esteemed Clients",
      main: "The Buzz Word",
    },
    proud_work: {
      sub: "Work We Are Proud Of",
      main: "We Are A Whole Package To Spark Growth, & Break Business Boundaries",
      cards: [
        {
          src: CoinhavenLogo,
          title: "Coinhaven",
          href: "/projects/#coinhaven",
          description:
            "Coinhaven is an all-in-one trading and exchange platform for desktop and mobile devices where users can easily buy Bitcoin and other blockchain assets with a variety of fiat currencies. Users can also exchange their Bitcoin for various cryptocurrencies – such as Ethereum – with just a few clicks....",
          alt: "Coinhaven",
        },
        {
          src: TolobiLogo,
          title: "Tolobi",
          href: "/projects/#tolobi",
          description:
            "This project is, at its core, a real estate listing platform where landlords can list their properties for lease and interested tenants can browse and get in touch in case they like any of the listings. The level of detail in this project was very meticulous. we had to optimize an existing poorly managed....",
          alt: "Tolobi",
        },
        {
          src: BlackhedgeLogo,
          title: "BlackHedge",
          href: "/projects/#blackhedge",
          description:
            "Blackhedge is a real-time data provider for multiple cryptocurrencies which fetches raw data from multiple sources and combines them in a way that makes it easier to compare and judge behavioral patterns. We also created a bot for the project that could suggest buying and....",
          alt: "BlackHedge",
        },
      ],
      button: "Explore More",
      link: "/projects",
    },
    tools_trade: {
      sub: "Our Tools of Trade",
      main: "We Hold In-Depth Expertise In Future Proof Technologies",
      button: "Explore More",
      link: "/technologies",
    },
    testimonial: {
      sub: "What Our Clients Say",
      main: "Trusted By Our Esteemed Clients",
      reviews: [
        {
          src: Sabur,
          alt: "SABUR MORTAZAWI",
          name: "SABUR MORTAZAWI",
          info: "Founder & CEO, Taxionspot",
          review:
            "Two months after onboarding the Ellocent Labs team for the project, the company saw the number of rides booked through the website rose from zero per day to ten per day. The company appreciates how the team sent their updates on time or provided reasons for any delay in advance.",
          rating: 5,
          type: "Ride Booking",
        },
        {
          src: Zohaib,
          alt: "Zohaib Shahab",
          name: "Zohaib Shahab",
          info: "Product Manager, Tolobi",
          review:
            "Sundeep is a pleasure to work with and truly cares about meeting a customer's needs. The web dev agency she is leading is a great organization.",
          rating: 5,
          type: "Real Estate",
        },
        {
          src: Danish,
          name: "Danish Sayed",
          alt: "Danish Sayed",
          info: "Co-founder, BlackHedge",
          review:
            "We have contracted Ello Labs for over a year now and we are very pleased with the work that they are able to provide, not only that but they deliver on the expected time frame and are communicative of any issues that they are facing. They are very quick to resolve bugs and any discrepancies in the project. They are very talented in full stack development.",
          rating: 5,
          type: "Cryptocurrency/Blockchain",
        },
        {
          src: Mark,
          name: "Mark Jacobsen",
          alt: "Mark Jacobsen",
          info: "Director, Cryptoleagues",
          review:
            "Ellocent Labs has assisted us in creating core parts of our platform and have been very professional and helpful at all times. The team is highly responsive and does not shy away from challenges and I can give my recommendations as the team develops well-crafted and thought-out solutions.",
          rating: 5,
          type: "Cryptocurrency/Blockchain",
        },
        {
          src: Boulyn,
          name: "Boualy",
          alt: "Boualy",
          info: "Founder, Pandaoptics",
          review:
            "It is rare to come across a team of developers as available and talented as that of clickandcollectinthebox.fr. Throughout the work of creating the site, the developers have listened to each of our complaints. They are very open and courteous. They understood our needs and met them.!",
          rating: 5,
          type: "Cryptocurrency/Blockchain",
        },
        {
          src: Shivani,
          name: "Shivani Bhan Dhar",
          alt: "Shivani Bhan Dhar",
          info: "Founder & Creator, Kashmir As It Is",
          review:
            "I have been working with ELLOCENT LABS from the past couple of years as they are helping me in developing my application. Working with the team has been a great experience. They have been focused on the quality of work more than quantity.",
          rating: 5,
          type: "E-Commerce",
        },
      ],
    },
    blog: {
      sub: "News and Blog",
      main: "Stay in the loop of tech world",
      button: "Explore More Blog",
      link: "/blog",
    },
    contact_form: {
      sub: "Reach Us Out",
      main: "Unstoppable Innovation Starts Here",
    },
    contactComment_form: {
      sub: "Leave a Reply",
      // main: "Unstoppable Innovation Starts Here",
    },
  },

  technology: {
    header: {
      headerimage: PrivacyHeader,
      main_heading: "Technologies",
      active_page: "Technologies",
      alt: "Technologies",
    },
    banner: {
      image: TechBanner,
      alt: "Future-Proof Technologies",
    },
    sub: "Technology Stack",
    main: "Scale Your Business With Future-Proof Technologies",
    description:
      "Ellocent Labs IT Solutions Pvt. Ltd. is an emerging software solution provider company in India. We have a seasoned team of developers, designers, and testers who are chosen for their commitment to quality and exemplary skills. Our developers employ the latest technologies, frameworks, and components to create efficient solutions per today's digital world demands and requirements. To build stellar software, some technologies are best for developing the front end, and some are best for the back end. Well, our seasoned team can direct you to the specific needs of the project. Additionally, we work closely with our precious clients to turn their dreams into reality, integrate their feedback into projects, and develop efficient software solutions.",
    tool_trade: {
      sub: "Our Tools Of Trade",
      main: "We Hold In-Depth Expertise",
    },
  },
  industries: {
    header: {
      headerimage: IndustryHeader,
      main_heading: "Industries",
      active_page: "Industries",
      alt: "Industries",
    },
    about: {
      subheading: "Our Industries",
      heading: "Innovations In Key Industries",
      description:
        "In today’s digital world, Real-time operations and transparency are essential for every industry. We are an interactive team of developers that build award-winning websites, softwares, and applications for diverse domains. With the advanced technologies, We can Turn your idea into groundbreaking solutions.",
    },
    cards: [
      {
        icon: Healthcare,
        alt: "Health",
        title: "Health",
        description:
          "Do You Have A Zeal To Enhance The Patient’s Experience In The Healthcare Industry?   Ellocent Labs IT Solutions Pvt. Ltd. Offers A Wide Range Of Healthcare Solutions. Till Now, We Have Successfully Delivered Top-Notch Medical Software Solutions For Various Medical Service Institutions.",
      },
      {
        icon: RealEstate,
        title: "Real Estate",
        alt: "Real Estate",
        description:
          "Our seasoned team of developers offers first-class real estate services to our huge client base with all the cutting-edge technologies and top- notch features for the real estate platform.",
      },
      {
        icon: Travel,
        title: "Travel/Tourism",
        alt: "Travel",
        description:
          "If you want to build a travel app, then you are at the right place. Our proficient team got the skills, expertise, and technology to develop the travel app for your business.",
      },
      {
        icon: Music,
        title: "Music",
        alt: "Music",
        description:
          "As each sector develops at high speed, the music industry requires an Out-of-the-Box approach. We offer a seamless user interface, navigation, and a positive attitude to accept new challenges in the workspace.",
      },
      {
        icon: Trading,
        title: "Trading",
        alt: "Trading",
        description:
          "We have an agile team of developers who are experienced in developing trading platforms according to client requirements like user can learn how to trade as per the market fluctuations.",
      },
      {
        icon: Bitcoin,
        title: "Cryptocurrency",
        alt: "Cryptocurrency",
        description:
          "Cryptocurrency is the latest emerging trend in the field of technology. We provide efficient solutions for various startups, enterprises, and other businesses. Additionally, we offer a secure, immutable, and easy-to-use exchange platform with an efficient real-time trading system.",
      },
      {
        icon: ECommerce,
        title: "E-commerce",
        alt: "E-Commerce",
        description:
          "Your search for an online partner ends with our innovative software solutions. At Ellocent Labs IT Solutions Pvt. Ltd., We have a rich portfolio of e-commerce solutions for diverse industries. Our experts know all the right things to convert your small idea into a leveraging business.",
      },
      {
        icon: ELearning,
        title: "E-learning",
        alt: "E-learning",
        description:
          "In this digital age, our E-Learning development services will help diverse institutions, universities, colleges, and schools increase student engagement by digitizing the learning. Apps are vogue these days because they satisfy people’s demand for learning a plethora of online topics.",
      },
      {
        icon: Informational,
        title: "Informational",
        alt: "Informational",
        description:
          "We have an ace team of developers who developed informational websites for client’s business growth. With Ellocent Labs IT Solutions, you can have access to quality talent.",
      },
      {
        icon: RideBooking,
        title: "Ride Booking",
        alt: "Ride Booking",
        description:
          "We are perfectly armed with a team of experienced taxi hiring app developers. Additionally, we hold vast experience in developing ride-booking apps. Till now, we have developed several ride-booking apps for our esteemed client base globally.",
      },
      {
        icon: Blockchain,
        title: "Blockchain",
        alt: "Blockchain",
        description:
          "If you want to equip your business with secure, decentralized blockchain solutions, then Ellocent Labs IT Solutions Pvt. Ltd. Is the best fit for you. so upscale your business with our wide spectrum of blockchain development services.",
      },
      {
        icon: Bot,
        title: "Bot",
        alt: "Bot",
        description:
          "Bots are automated hardware or software machines powered by Artificial Intelligence development services. Additionally, Chatbots are software programs that can simulate human conversation. Besides they are also known by the name of virtual agents, virtual assistants, and bots.",
      },
      {
        icon: Airlines,
        title: "Airlines",
        alt: "Airlines",
        description:
          "Propel your airline service to the next level by hiring our airline domain specialists. They are known for implementing a cutting-edge tech stack to build the best software solution as per the client’s needs.",
      },
      {
        icon: Bigdata,
        title: "Big Data",
        alt: "Big Data",
        description:
          "The term Big Data refers to large, complex, and hard to manage data which grows exponentially with time. The data is so large in volume that even the traditional data management tools enable it to store and analyze. Additionally this massive data can be used to address business problems .",
      },
      {
        icon: FoodOrder,
        title: "Food",
        alt: "Food",
        description:
          "We have extensive exposure to working with the food and restaurant domain. Our innovative customer solutions help our esteemed restaurant industry clients streamline their working processes.",
      },
      {
        icon: QRCode,
        title: "QR Code",
        alt: "Qr Code",
        description:
          "The QR Code is one of the most effective marketing tools you can use to lead your visitors to valuable content on your website. With our QR Code services you can easily avail coupon redemption, enhanced social media reach, and easier app installation for your audience.",
      },
      {
        icon: IOT,
        title: "Iot",
        alt: "Iot",
        description:
          "IoT stands for internet of things, and we are professionals in developing IoT Based Solutions As Per The Client’s Demands. Besides, our IoT services will help you unify your business infrastructure, services, applications, and workflow.",
      },
      {
        icon: Entertainment,
        title: "Entertainment",
        alt: "Entertainment",
        description:
          "We offer custom media and entertainment mobile app development solutions to our prestigious clients based as per their specifications. The unique app designed will not only become a household name, but also remain above the ever-growing crowd of entertainment mobile apps.",
      },
    ],
  },
  portfolio: {
    products: {
      header: {
        heading: "Products",
        active_page: "Products",
        alt: "Products Header Image",
        headerimage: ProductHeader,
      },
      banner: {
        main: "The Products We Owned Proudly",
        sub: "Exceptional Digital Products",
        description:
          "We have talented developers who can easily transform your bold business idea into exceptional digital products. So, if you are searching for a partner that will give wings to your idea by turning it into reality, you are at the right place.<br><br>At Ellocent Labs IT Solutions Pvt. Ltd., we have a talented pool of developers, designers, testers, and business analysts, who ideate, design, and develop data-driven digital products ready to face business challenges. Besides, we offer a complete suite of services to create a seamless digital masterpiece. So, explore our services and let us be your light on the path of digital product development.<br><br>We have developed our two prominent products as per today's business time. Our first product is <b>Ello Covid Tracer</b>, and the Second one is <b>OCR( Optical Character Recognition)</b>.",
        btn1: "Ello Covid Tracker",
        btn2: "Optical Character Recognition",
      },
      covid_tracer: {
        sub: "Our Product",
        main: "Ello Covid Tracer",
        bannerimage: QRBanner,
        alt: "QR Banner",
        description:
          "A Tool for Contact Tracing QR Code/ Contactless Check-in for Restaurants, Cafes, Departmental Stores & Hotels Ello COVID Tracer helps in Contactless check-ins during this pandemic COVID-19 situation. Here, you can easily check-in,via keyless entrance system, inside a restaurant, cafe, hotels, etc. without any physical contact. As a guest, you don’t have to queue in a line. This allow customers to scan a QR code at the entrance to check-in and register their contact details. In this way, your customers will get access to a seamless, safe, secured and reliable check-in process. On the other hand, as an admin, the hotel, cafe, restaurant owners will also be highly privileged as it reduces their front desk traffic, saves unnecessary time and expenses spent in the form of employee or physical key making.",
      },
      features: {
        main: "Prominent Features & Benefits",
        featurestitle: "Features",
        benefitstitle: "Benefits",
        icon: Liarrow,
        alt: "",
        featurelist: [
          {
            title: "No more app installation required",
          },
          {
            title: "Easy Customer information collection",
          },
          {
            title: "Fast processing time",
          },
          {
            title: "Interactive User Interface for Restaurant Owners",
          },
          {
            title: "Export Customers data in user friendly format",
          },
          {
            title: "Directly download or print the QR code through Admin panel",
          },
        ],
        benefitlist: [
          {
            title: "Hassle free QR Code scanning",
          },
          {
            title: "Contactless Check-in",
          },
          {
            title:
              "Easy tracking of people during COVID situations as per Government guidelines",
          },
        ],
      },
      covidtimeline: {
        main: "Why Ello COVID Safe?",
        firsttitle: "Quick & Easy",
        firstpara:
          "The year 2020 has been the year of COVID-19 and it has certainly turned out to be the worst year we have experienced as a generation. Every activity around the world has been hit immensely including sports, hotel businesses, restaurants, cafes, and several other small and big ventures. To execute all these activities today in a smooth way demands various government protocols like contact tracing, to be followed strictly. To make this possible, Ello COVID has come up with an efficient way of contact tracing that will help all these enterprises blossom again. Ello COVID makes contact tracing quick and easy by saving plenty of time. It takes care of the complete process right from start to finish.",
        secondtitle: "Safe & Simple",
        secondpara:
          "Ensuring safety has become a priority before going out these days. Stepping out either to play your favorite sport or to visit a restaurant or cafe of your choice has become a little different today. Ello COVID is determined to keep it safe and simple for you. Features like paperless and contactless sign-in along with accurate and fast response keep it simple yet effective. It also allows you to use your mobile’s web browser which is the safest way to distance yourself from any other contact.",
        thirdtitle: "Budget Friendly",
        thirdpara:
          "COVID is playing tough on us and has made many of us financially unstable. Considering this, the budget-friendly COVID tracing tool at Ello COVID plays a crucial role in providing a cost-effective solution that saves the administration time and does not bother you to think much of the money aspect.",
        fourthtitle: "Peace of Mind",
        fourthpara:
          "The satisfaction of knowing that you have been fully covered with all your needs is unmatchable. This is exactly what Ello COVID does to bring you the peace of mind. It adheres to all the new government regulations to keep your customer and business member’s safe. The priority here is to keep your club’s/association’s/business’s data secure. All your information is protected using highly advanced technology along with some concrete security measures.",
        fifthtitle: "Easy Acces",
        fifthpara:
          "For any service to work efficiently, it is important that it provides hassle-free and easy access to its users. Here at Ello COVID, your club’s/businesses authorized administrator will have access to all the records anytime, anywhere at his fingertips. The entire information is saved in one location and can be shared with the relevant state health authorities if and when needed. This makes it even smooth in keeping the communities connected.",
        sixthtitle: "Captures Real-Time Data",
        sixthpara:
          "For all the contact tracings done; the tool captures real-time data and provides a precise quick response. Every sign-in form is time-stamped which is a must-have for an accurate contact tracing. Real-time data helps in achieving desired results and also proves valuable in maintaining correct records.",
      },
      steps: {
        sub: "Our Product",
        main: "So How Does It Work?",
      },
      ocr: {
        sub: "Our Product",
        main: "Why OCR ?",
        bannerimage: OCRBanner,
        alt: "OCR Banner",
        description:
          "Suppose you wanted to digitize an article or a printed contract. You’ll spend hours retyping then correcting misprints. In several minutes, you’ll convert all the required material into digital format, employing a scanner (or a digital camera) and Optical Character Recognition software.",
      },
      ocr_timeline: {
        main: "Why Optical Character Recognition?",
        firsttitle: "Solution For OCR",
        firstpara:
          "The exact mechanisms that allow humans to acknowledge objects are yet to be understood. Integrity, purposefulness, adaptableness (IPA) is the three fundamental principles. Scientists document these three principles.<br> These principles constitute the core of OCR, allowing it to duplicate natural or human-like recognition.<br> Let’s take a glance at how OCR recognizes text. First, the program analyzes the structure of the document image. It divides the page into elements like blocks of texts, tables, images, and lines divided into words then – into characters. The program compares them with a group of pattern images, once the characters singled out. It advances numerous hypotheses about what this character is. Based on these hypotheses, the program analyzes different variants of breaking of lines into words and words into aspects. After processing a vast number of probabilistic predictions, the program finally takes the choice, presenting you with the recognized text.<br> A perfect solution to convert text and pictures from your scanned PDF document into the editable Excel format. Converted documents look precisely just like the original – tables, columns, and graphics.",
        secondtitle: "Behind OCR,<br> Technology Lies",
        secondpara:
          "We can convert the differing types of documents, like scanned paper documents, PDF files, or images captured by a camera into </h3>editable and searchable data with the help of the technology named OCR.<br> Imagine you’ve got a paper document – for instance, an article, brochure, or PDF contract your partner sent to you by email. A scanner isn’t enough to form this information available for editing, say in Microsoft Word. All a scanner can do is create a picture or a snapshot of the document that’s nothing quite a set of black and white or color dots, referred to as a raster image. To extract and repurpose data from scanned documents, camera images, or image-only PDFs, you would like OCR software that might single out letters on the model. Put them into words then – words into sentences, thus enabling you to access and edit the content of the first document.",
        thirdtitle:
          "Recognition Of Image<br> Which Captured By <br>Digital Camera",
        thirdpara:
          "Images captured by a camera differ from scanned documents or image-only PDFs. They often have defects like distortion at the sides and dimmed light, making it difficult for many OCR applications to recognize the text correctly. The newest version of OCR supports adaptive recognition technology specifically designed for processing camera images. It offers a variety of features to enhance such images’ standards, providing you with the power to completely use your digital devices’ capabilities.",
        fourthtitle: "Way To Use OCR<br> Software",
        fourthpara:
          "Using OCR is unchallenging: the method generally consists of three stages: Open (Scan) the document, Recognize it then Save during a convenient format (DOC, RTF, XLS, PDF, HTML, TXT) or export data on to at least one among Office applications like Google Drive, CSV, Excel.<br> In the starting, there are the options to Sign up through Gmail and Google too. Here is the option of forgot password. We can recover the password as many times as we want. The way this works is two ways.<br><b>First</b>, the document, whether PDF, image, it will be scanned and will be downloaded automatically, without our permission.<br><b>Secondly</b>, whether the document is in the image or PDF format Will be scanned, and the result will show in the tabular and section form. Here we have three options to download the report, i.e., Google Drive, CSV, Excel format. Here the download of the document depends upon us whether we want to download the file or not. If we wish to, then we can download the file else we can skip this option of download.<br> Here are other beautiful features of this OCR is Area Scan. In the area scan, we can select the text portion to get data by scanning that specific portion of the document. Here we can choose the multiple or single part of the material. We can scan the barcode and signature, too, and can save in the proper document format.",
        fifthtitle: "Benefits",
        fifthpara:
          "With OCR, the recognized document looks a bit like the first. Advanced, powerful OCR software allows you to save lots of tons of your time and energy when creating, processing, and repurposing various documents. With OCR, you’ll scan paper documents for further editing and sharing with your colleagues and partners. You’ll extract quotes from books and magazines and use them for creating your course studies and papers without the necessity of retyping. With a camera and OCR, you’ll capture text outdoors from banners, posters, and timetables then use the obtained information for your purposes. Within the same way, you’ll capture data from paper documents and books – for instance, if there’s no scanner close at hand. Otherwise, you cannot use it. Additionally, you’ll use OCR software for creating searchable PDF archives.",
        sixthtitle: "Conclusion",
        sixthpara:
          "The entire process of knowledge conversion from an original paper document, image, or PDF takes but a moment. Therefore the final recognized report looks a bit like the original!",
      },
    },
    projects: {
      header: {
        heading: "Projects",
        active_page: "Projects",
        alt: "Projects",
      },
    },
  },
  company: {
    how_we_work: {
      header: {
        heading: "How We Work",
        active_page: "How We Work",
        alt: "How We Work",
        headerimage: HowweworkHeader,
      },
      banner: {
        sub: "How We Work",
        main: "Client's Business As Is Own",
        bannerimage: HowweworkBanner,
        alt: "Work Banner",
        para1:
          "We strive to deliver our best to our client over the globe by our unmatched skills & experience. We take an integrated approach for developing highly engaging, professional & brand focused creative solutions.",
      },
      agile: {
        subheading: "Agile",
        title: "Our Work Approach",
        description:
          "We at Ellocent Labs IT Solutions Pvt. Ltd. use Scrum, the most popular Agile Framework in our software development process. This is an iterative approach that has at its core the sprint — the scrum term for iteration. Agile is an iterative process that allows us to deliver working features through a basic solution and then we enhance it further over time. This makes the project delivery a quick process. And throughout the whole process we do have a constant communication and interaction with our clients to ensure that they are happy with every sprint release.<br><br>The scrum approach includes assembling the project’s requirements in a Product Backlog ( prepared by the product owner ) and using them to define the project. We then plan the necessary sprints known as Sprint Backlog that lasts from 2-3 weeks, and divide each sprint into its own list of requirements or features. Daily scrum meetings help keep the project on target as do regular inspections and Sprint Reviews. At the end of every sprint, we hold a sprint retrospective to look for ways to improve the next sprint.",
        icons: [
          {
            icon: ALogo,
            alt: "ALogo",
          },
          {
            icon: GitLogo,
            alt: "Git Logo",
          },
          {
            icon: VectorLogo,
            alt: "Vector Logo",
          },
          {
            icon: Mobile,
            alt: "Mobile",
          },
          {
            icon: Notepad,
            alt: "Notepad",
          },
          {
            icon: SkypeLogo,
            alt: "Skype",
          },
        ],
      },
      rituals: {
        main: "Agile Rituals",
        first: {
          number: "01",
          title: "Sprint Planning",
          description:
            "Any work that is to be performed in the Sprint is planned at the Sprint Planning. It is time-boxed to a maximum of eight hours for a month-long Sprint.",
        },
        second: {
          number: "02",
          title: "Daily Stand Up<br> Meeting",
          description:
            "It provides a simple means for team members to keep each other up to date without spending a lot of time in meetings or having to write and read of status reports.",
        },
        third: {
          number: "03",
          title: "Sprint Review",
          description:
            "The sprint review is focused on the product increment created during the sprint. It starts with a customer review and demonstration and ends with discussion and updates to the product backlog and release plan.",
        },
        fourth: {
          number: "04",
          title: "Sprint Retrospective",
          description:
            "Meaningful retrospectives resolve project impediments, identify key issues, and create an environment of continuous improvement.",
        },
      },
    },
    career: {
      header: {
        headerimage: CareerHeader,
        heading: "Career",
        active_page: "Career",
        alt: "Career",
      },
      banner: {
        sub: "Why Us",
        main: "Why Work With Ellocent Labs?",
        description:
          "We apply agile methodologies to produce incredible outcomes, and that’s what places us as one among the most prominent software Development Company. We genuinely committed to render overall IT solutions under one roof ranging from Application Development to end to end IT Implementation projects. We present a perfectly-balanced amalgamation of functional & operational knowledge, technical expertise and result-oriented management experience.",
      },
      jobs: {
        sub: "Job Openings",
        main: "Explore with ELIS",
      },
      cards: [
        {
          icon: DevelopmentIcon,
          alt: "Ionic Developer",
          title: "Sr. Ionic<br> Developer",
          position: "Ionic Developer",
          location: "Mohali, Punjab",
          user: UserIcon,
          map: MapIcon,
          link: "/jobs-opening/ionic",
          explore: "Explore More",
          arrow: ArrowIcon,
        },
        {
          icon: UXIcon,
          alt: "WordPress Developer",
          title: "Sr. WordPress<br> Developer",
          position: "WordPress Developer",
          location: "Mohali, Punjab",
          user: UserIcon,
          map: MapIcon,
          link: "/jobs-opening/wordpress",
          explore: "Explore More",
          arrow: ArrowIcon,
        },

        {
          icon: CodingIcon,
          alt: "Angular Developer",
          title: "Sr. Angular<br> Developer",
          position: "Angular Developer",
          location: "Mohali, Punjab",
          user: UserIcon,
          map: MapIcon,
          link: "/jobs-opening/angular",
          explore: "Explore More",
          arrow: ArrowIcon,
        },
        {
          icon: VectorIcon,
          alt: "UI/UX Designer",
          title: "Sr. UI/UX<br> Designer",
          position: "UI/UX Designer",
          location: "Mohali, Punjab",
          user: UserIcon,
          map: MapIcon,
          link: "/jobs-opening/ui-ux",
          explore: "Explore More",
          arrow: ArrowIcon,
        },
      ],
    },
    about_us: {
      header: {
        headerimage: AboutHeader,
        heading: "About Us",
        active_page: "About Us",
        alt: "About Us",
      },
      banner: {
        sub: "About Us",
        main: "Connecting things, It's all about people",
        description:
          "Ellocent Labs IT Solutions Pvt. Ltd. is an esteemed organization with a positive and productive vision to provide Software Development and web application development services and solutions relevant to the IT sectors. We corely believe in quality services and thus, bestow our respective clients with unique business solutions. We potentially help you and your business to connect with customers, integrate with vendors and empower employees to work employing the fullest efforts and calibre using the advanced tool, techniques and strategies.",
        image: AboutBanner,
        alt: "About Us",
        icon: TickIcon,
        list: [
          {
            title: "Quick To Respond",
          },
          {
            title: "Flexible Price",
          },
          {
            title: "24/7 Hours Support",
          },
          {
            title: "Verified Professionals",
          },
          {
            title: "Conscientious",
          },
          {
            title: "Ontime at Services",
          },
        ],
      },
      our_best: {
        sub: "What We Do Best",
        main: "We deal with the aspects of Professional IT Services",
        description:
          "We thrive our best to craft excellent outcomes and productivity. Our best-in-technological strategies encourage us to cater to a good experience in information technology, customer service, and global operations as well. We provide you with the dual-edged advantage of being both dynamic and stable providing scalability, stability and higher credibility to your business and allow your business to survive effectively with the fast-changing world of Information Technology.",
      },
      proud_work: {
        sub: "WORK WE ARE PROUD OF",
        main: "We Are A Whole Package To Spark Growth, & Break Business Boundaries",
        button: "EXPLORE MORE",
        link: "/projects",
        cards: [
          {
            icon: GurbaniUpdesh,
            title: "GURBANI UPDESH",
            description:
              "The user can enjoy the interactive, userfriendly app as it is available in bi-languages like English and Punjabi. The main attractive feature of this app as it offers a five paths- Japji Sahib ji, Jaap Sahibn ji, Tav Prasad Saviye ji, Chaupai Sahib ji....",
          },
          {
            icon: ZappTaxi,
            title: "Zapp Taxi",
            description:
              "In recent months, we have developed the zapp customer app for our Netherlands-based client. It gives the full privilege to customers to book the ride by sending the booking request to nearby drivers. The various functionalities of this app are ride....",
          },
          // {
          //   icon: Cryptoleagues,
          //   title: "CRYPTO LEAGUES",
          //   description:"Coinhaven is an all-in-one trading and exchange platform for desktop and mobile devices where users can easily buy Bitcoin and other blockchain assets with a variety of fiat currencies. Users can also exchange their Bitcoin for various cryptocurrencies – such as Ethereum – with just a few clicks...."
          //     "This is a project about trading in different cryptocurrencies. The user can trade with a platform currency. Initially, the user is provided 10,000 Currency points which are used to buy any type of crypto-currency available on the platform....",
          // },
          {
            icon: CoinHaven,
            title: "COINhaven",
            description:
              "Coinhaven is an all-in-one trading and exchange platform for desktop and mobile devices where users can easily buy Bitcoin and other blockchain assets with a variety of fiat currencies. Users can also exchange their Bitcoin for various cryptocurrencies – such as Ethereum – with just a few clicks....Coinhaven is an all-in-one trading and exchange platform for desktop and mobile devices where users can easily buy Bitcoin and other blockchain assets with a variety of fiat currencies. Users can also exchange their Bitcoin for various cryptocurrencies – such as Ethereum – with just a few clicks....Coinhaven is an all-in-one trading and exchange platform for desktop and mobile devices where users can easily buy Bitcoin and other blockchain assets with a variety of fiat currencies. Users can also exchange their Bitcoin for various cryptocurrencies – such as Ethereum – with just a few clicks....",
          },
        ],
      },
    },
  },
  contact: {
    header: {
      headerimage: ContactHeader,
      heading: "Contact Us",
      active_page: "Contact Us",
      alt: "Contact Us",
    },
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.5405752466704!2d76.68996561432552!3d30.703199981647415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feefc6d84d361%3A0xdff70f3b5d78a533!2sSTPI%20Incubation%20Center%20Mohali!5e0!3m2!1sen!2sin!4v1658476053213!5m2!1sen!2sin",
    about: {
      subheading: "Visit Us At",
      main: "Our Address",

      contactdetail: [
        {
          icon: LocationIcon,
          title:
            "Mohali<br> Plot No. C-184, 4th Floor, Industrial Area, Phase­ VIII­A, Sector 75, Sahibzada Ajit Singh Nagar, Punjab 160071, India",
          alt: "Location",
        },
        {
          icon: PhoneIcon,
          title: "+91-749-967-7200",
          link: "tel:+91 7499677200",
          alt: "Phone",
        },
        [
          {
            icon: EmailIcon,
            title: "sales@ellocentlabs.com",
            link: "mailto:sales@ellocentlabs.com",
            alt: "Email",
          },
          {
            icon: EmailIcon,
            title: "hr@ellocentlabs.com",
            link: "mailto:hr@ellocentlabs.com",
            alt: "alt",
          },
        ],
      ],
    },
  },
  what_we_do: {
    parent: "What We Do",

    mobile_development: {
      header_image: MobileDevelopment,
      heading: "Mobile App Development",
      description:
        " At Elocent Labs, we specialize in creating innovative and user-friendly hybrid mobile applications that work seamlessly across different platforms. Our team of experienced developers combines the power of cutting-edge technologies and frameworks to deliver high-quality hybrid apps tailored to your business needs.<br><br>Hybrid app development refers to the process of creating mobile applications that can run on multiple platforms, such as IOS and Android, using a single codebase. Unlike native app development, where separate codebases are required for each platform, hybrid app development utilizes frameworks and technologies that allow developers to write code once and deploy it across multiple platforms.<br><br>This approach combines the benefits of web development (using web technologies like HTML, CSS, and JavaScript) with the ability to access device features and capabilities through native APIs. Hybrid apps offer cost-effectiveness, faster development cycles, and the ability to reach a wider audience by supporting multiple platforms, making them a popular choice for businesses looking to develop mobile applications efficiently.",
      react_native: {
        header: {
          heading: "React Native",
          sub_parent: "Mobile App Development",
          sub_parent_link: "/mobile-app-development",

          active_page: "React Native",
          alt: "React Native",
          headerimage: ReactHeader,
        },
        banner: {
          title:
            "Grow Your Business With Our Best React Native App Development Services",
          description:
            "In this modern world of technology, the mobile app has become an integral part of our lives. With the advancement of technology, there is a surge in apps. We can find numerous apps on the Google appstore or Apple store. You must be wondering to know that there are almost 3,739 apps added to the playstore every day.<br> Suppose you are a business owner or a startup planning to launch the app but want to reduce operational costs and development time. In that case, a cross-platform framework like React Native app development can be a suitable fit for you. The professional team at Ellocent Labs IT Solutions Pvt. Ltd. has extensive expertise in developing the react native app from scratch.",
          alt: "React Native App Development",
          bannerimage: ReactBanner,
        },
        benefits: {
          subheading: "Benefits Of React Native",
          title:
            "Incredible benefits of Picking the<br> React Native App Development",
          title2:
            "Incredible benefits of Picking the React Native App Development",
          cards: [
            {
              icon: Code,
              alt: "code",
              title: "Code Reusability",
              description:
                "The professional development team has the privilege to use the same code for deployment on iOS and Android, which leads to huge savings in time and cost.",
            },
            {
              icon: Community,
              alt: "community",
              title: "Large Community",
              description:
                "It’s an open-source framework that enables the react developers to access all relevant documentation without any cost. The developers can easily give feedback to newbies through forum discussions.",
            },
            {
              icon: PerformanceImg,
              alt: "performance",
              title: "Great Performance",
              description:
                "React native app development performs like native app development and leverages the GPU, which helps speed up the application.",
            },
            {
              icon: Loading,
              alt: "loading",
              title: "Live/Hot Reloading",
              description:
                "There is a slight difference between live reloading and hot reloading. In live reloading, the entire application is reloading, whereas hot reloading refreshes a file that has been edited or changed.",
            },
          ],
        },
        native_benefits: {
          row1: [
            {
              title: "Designing",
              description:
                "In this step, our design team will design the app's interactive and engaging UI/UX as per the client's needs by selecting the best technologies and frameworks.",
            },
            {
              title: "Testing",
              description:
                "After all the development, the app will be gone under the testing section, where the QA team checks the bugs and ensures that the high-quality app will be delivered.",
            },
          ],
          row2: [
            {
              title: "Ideation",
              description:
                "Our professional development team conducts the brainstorming session on a client's idea by creating a checklist of features and core functionality.",
            },
            {
              title: "Development",
              description:
                "Once the app design is finalized, our development team will start working on it by adding functionality and making it more responsive.",
            },
            {
              title: "Release",
              description:
                "You have developed and tested your app, so now is the time to launch your app on the Apple Store or Google Playstore after fulfilling the submission criteria.",
            },
          ],
        },
      },
      kotlin: {
        header: {
          heading: "Kotlin",
          sub_parent: "Mobile App Development",
          active_page: "Kotlin",
          alt: "Kotlin",
          headerimage: kotlinHeader,
        },
        banner: {
          title: "Android Development With Kotlin",
          description:
            "Kotlin is an open-source, general-purpose, cross-platform programming language. It was developed by JetBrains. With the approval of Google, it became the official language for Android Programming. Using this language makes the android native app development faster and has reduced the possibility of bugs, clearing the code readability. Additionally, Kotlin is a more powerful language than Java and is widely used for development due to its interactive benefits for building more secure and scalable futuristic apps.<br><br> We are a team of experts with continuous learning and exploring mindset with an experience of over 5+ years in the development field. With the advanced technologies and the marketing trend, we are developing future-ready solutions for our prestigious client base. Besides, we talk about another benefit of using Kotilin; it is also used as a server-side programming language for web development and big data science projects. Our ace team of Kotlin developers is capable of delivering innovative and interactive digital solutions across diverse domains.",
          alt: "Android Development",
          bannerimage: kotlinBanner,
        },
        choose: {
          title: "Why You Should Go With Kotlin Development",
          alt: "Kotlin Development",
          bannerimage: KotlinChoose,
          icon: TickIcon,
          list: [
            {
              title: "Statically-typed language",
            },
            {
              title: "Multi-Platform Support",
            },
            {
              title: "Java Compatibility",
            },
            {
              title: "Tool friendly Language",
            },
            {
              title: "Big Community Support",
            },
            {
              title: "Safer Codes",
            },
          ],
        },
        cards: {
          title: "Our Top-Notch Kotlin Development Services Include",
          description:
            "Our Kotlin app development experts can help you with everything from Android app development to server-side development. Our developers are a perfect suite of development services.",
          cards_data: [
            {
              title: "Kotlin Android App Development",
              description:
                "We are a team of expert vetted developers for building feature-rich, secure, cross-platform and powerful android apps using the kotlin programming language.",
            },
            {
              title: "Web<br> Development",
              description:
                "With Ellocent Labs IT Solutions, you can easily avail of our web development services by leveraging advanced technologies like kotlin. Get a powerful, scalable and secure web for your business growth.",
            },
            {
              title: "Server-Side Development",
              description:
                "Apart from development, Kotlin is also a great fit for developing the server-side application. While maintaining full compatibility with Java-based technology stacks allows developers to write concise, expressive code.",
            },
            {
              title: "Support and Maintenance",
              description:
                "We also provide 24*7 support and maintenance services for kotlin applications by ensuring an uninterrupted and glitch-free user experience.",
            },
          ],
        },
        whyellocent: {
          subheading: "Why Choose Us",
          bannerimage: KotlinVector,
          alt: "Innovative solutions",
          main: "Why Ellocent Labs<br> IT Solutions?",
          description:
            "<b>Innovative solutions:</b> Our team of experts delivers innovative, interactive solutions that give your business a competitive edge over the others. Besides, we always conduct brainstorming sessions before developing a solution so that the result will be fruitful for the clients.",
          description2:
            "<b>Secure solutions:</b> We always ensure that the interactive and engaging solutions we develop must be highly secure. To ensure this, we always follow data security standards.",
          description3:
            "<b>Cost-effective Solutions:</b> Our kotlin development solutions are always designed cost-effectively to meet complex business requirements and address the massive audience base with interactive features.",
        },
      },
      hybrid_service_list: [
        {
          service_icon: ReactHeader,
          service_heading: "React Native Development",
          service_link: "/react-native",

          service_des:
            "Our experts utilize React Native, a popular JavaScript framework, to build cross-platform applications that run smoothly on both iOS and Android devices. React Native allows us to write a single codebase that can be shared across platforms, significantly reducing development time and costs.",
        },
        {
          service_icon: kotlinHeader,
          service_heading: "Kotlin Development",
          service_link: "/kotlin",

          service_des:
            "Kotlin is a modern, versatile programming language that enables us to develop high-quality Android applications. As an official language for Android development, Kotlin offers numerous advantages, including improved code readability, enhanced productivity, and seamless interoperability with existing Java code. ",
        },
      ],

      home: {
        header: {
          heading: "Mobile App Development",
          active_page: "Mobile App Development",
          alt: "Mobile App Development",
          image: Hybrid_ST,
        },
        application: {
          sub: "Applications",
          main: "Mobile App Development Applications",
          list: [
            {
              title: "React<br> Native",
              number: One,
              icon: CVision,
              link: "/react-native",
            },
            {
              title: "Kotlin",
              number: Two,
              icon: NLP,
              link: "/kotlin",
            },
          ],
        },
      },
    },
    design_service: {
      header_image: Designing,
      heading: "Designing Services",
      description:
        "At Ellocent Labs, we offer a wide range of design services that are focused on enhancing the visual appeal and user experience of your digital products. Our team of innovative minds combines creativity and technical expertise to deliver compelling and user-centric designs.<br><br>Website development is an industry that undergoes frequent changes. To feed the current requirements and trends of people, experts have to come up with different ideas to see what works the most stylishly according to the current situation. Then's where the game lies.<br><br>The success of an online business depends solely on its web operation as that's how people come to know about your business or brand which in turn increases your business deals and performance from time to time. From CMS websites to e-Commerce platforms, we will help you set- up effects the way you need for the successful growth of your business",

      tools: {
        sub: "Our Tools Of Trade",
        main: "Tools & Software used for UI/UX Design",
      },
      design_service_list: [
        {
          service_icon: GraphicHeader,
          service_heading: "Graphic Design",
          service_link: "/graphic-designing",

          service_des:
            "Graphic design is all about creating visually captivating and impactful designs that effectively communicate your brand message. Our graphic design services encompass various aspects such as logo design, branding, print materials, digital advertisements, and more.",
        },
        {
          service_icon: UIHeader,
          service_heading: "UI/UX Designing",
          service_link: "/ui-ux-designing",

          service_des:
            "User Interface (UI) and User Experience (UX) are crucial elements in creating intuitive and engaging digital experiences. Our UI/UX design services focus on crafting user-friendly interfaces that not only look visually appealing but also offer seamless and enjoyable interactions.",
        },
      ],
      ui_ux: {
        header: {
          heading: "UI/UX Designing",
          sub_parent: "Designing Services",
          sub_parent_link: "/designing-services",
          active_page: "UI/UX Designing",
          alt: "UI/UX Designs",
          headerimage: UIHeader,
        },
        banner: {
          title: "Unique Features Tailored To Your Business Needs",
          description:
            "At Ellocent Labs IT Solutions Pvt. Ltd., we understand the importance and key role of good design in making the website/digital product successful. The experienced team of our UI/UX designers knows how to create appealing, eye-catcher, and optimized design solutions that meet the clients' needs and goals. Besides, our specialists use the perfect blend of tools and technologies to create innovative, responsive, and adaptive designs with uniform cross platform compatibility.<br><br>Further, our efficient design team is known for dedication, creative passion, and hands-on experience in all aspects of UI/UX designs. Additionally, we offer a delightful user experience that encourages users to linger more on the website or an app. Our designs are so compelling that they help your business to get the best and desired results.",
          alt: "UI-UX",
          bannerimage: UIBanner,
        },
        tools: {
          uiicons: [
            {
              icon: Figma,
              alt: "figma",
            },
            {
              icon: AdobeXD,
              alt: "adobexd",
            },
            {
              icon: Sketch,
              alt: "sketch",
            },
            {
              icon: Zaplin,
              alt: "zaplin",
            },
            {
              icon: AdobeAI,
              alt: "AdobeAI",
            },
            {
              icon: Coreldraw,
              alt: "Coreldraw",
            },
            {
              icon: Photoshop,
              alt: "Photoshop",
            },
            {
              icon: Invision,
              alt: "Invision",
            },
          ],
        },
        color_cards: {
          main: "Role of UI/UX Design",
          cards: [
            {
              icon: UXIcon,
              title: "User Experience (UX) Design",
              description:
                "UX refers to the user experience that a user has with a website, app, or digital product. Our professional team of Graphic designers designed the app or websites that gives 100% satisfaction to users when they interact with the app. Our designs are not only intuitive & engaging but also deliver the best measurable results.",
            },
            {
              icon: UIIcon,
              title: "User Interface (UI) Design",
              description:
                "UI refers to the User interface. The designer team puts a dedicated effort into the looks and style of the page so that the user will find it more pleasurable and stylish when they interact with it. There are three types of UI - GUI, VUI, and Gesture- based interface.",
            },
          ],
        },
        expertise: {
          main: "Our Interesting UI/UX Design Process",
          cards: [
            {
              icon: UserResearch,
              title: "User Research",
              description:
                "Our designer does in-depth user research in this phase, like how the target audience will feel when they interact with the product. Besides, we also analyze the potential risk factors and areas for improvement.",
            },
            {
              icon: UserPersona,
              title: "User Persona",
              description:
                "This step involves the behavioral information based on gender, age, income, likes, and dislikes. And the user journey also describes the particular behavior of the customer while performing the specific task.",
            },
            {
              icon: RequirementGathering,
              title: "Requirement Gathering",
              description:
                "This phase is all about collecting the requirement or information from the stakeholders. So, it takes a lot of research, an intensive understanding of what type of the product, and conducting the brainstorming session among the team members.",
            },
            {
              icon: Wireframe,
              title: "Wireframes",
              description:
                " It’s all about organizing the content and flow of a website or an app that allows the users to perform a particular action via navigation between the pages to complete the specific task and achieve the goals quickly.",
            },
            {
              icon: VisualDesign,
              title: "Visual Designs",
              description:
                " This step refers to the aesthetic look of the page or an app. Our team of professional experts focuses on creating a delightful and useful user experience.",
            },
            {
              icon: Prototype,
              title: "Prototypes",
              description:
                "We use the various types of graphic designing tools like invision, adobe XD while providing the contentment taste of what our clients want from us.",
            },
            {
              icon: Testing,
              title: "Testing",
              description:
                "Testing is the key to delivering a delightful and pleasurable user experience to the end-users.",
            },
          ],
        },
      },

      graphic: {
        header: {
          heading: "Graphic Designing",
          sub_parent: "Designing Services",
          active_page: "Graphic Designing",
          alt: "Graphic Designing",
          headerimage: GraphicHeader,
        },
        banner: {
          title:
            "Accelerate Your Business Growth With Our Graphic Designing Services",
          description:
            "When it comes to designing, there is no limit. With creative ideas and minds, we can touch the sky. The dedicated team of our designers are an expert in designing groundbreaking designs that will take your business to the next level.<br> Whether you want a stunning logo or flyers, the team of experts at Ellocent Labs IT Solutions Pvt, Ltd can make it happen for you. Our designer team creates magnificent designs that can easily transform the standards of your online business. We bring the perfect blend of graphic designing services that will suit all your business requirements irrespective of your domain. We are specialized in creating alluring designs.",
          alt: "Graphic Designing",
          bannerimage: GraphicBanner,
        },
        tools: {
          sub: "Our Tools Of Trade",
          main: "Tools & Software used for Graphic Designing",
          graphicicons: [
            {
              icon: Figma,
              alt: "Figma",
            },
            {
              icon: AdobeXD,
              alt: "AdobeXD",
            },
            {
              icon: Coreldraw,
              alt: "Coreldraw",
            },
            {
              icon: Photoshop,
              alt: "Photoshop",
            },
            {
              icon: AdobeAI,
              alt: "AdobeAI",
            },
          ],
        },
        workflow: {
          main: "Workflow Of Graphic Designing",
          first_col: [
            {
              icon: Idea,
              number: ONE_ICON,
              alt: "Idea",
              title: "Understanding",
              description:
                "The first and foremost step is to understand the clients' requirements and always ensure that we are on the same page.",
            },
            {
              icon: PC,
              number: FOUR_ICON,
              alt: "PC",
              title: "Make an Outline",
              description:
                "Now is the final time to put the ideas into the design paper and create various samples of it.",
            },
          ],
          second_col: [
            {
              icon: Research,
              number: TWO_ICON,
              alt: "Research",
              title: "Conduct Research",
              description:
                "Our efficient team of developers put thier special efforts into conducting deep research before starting the design work.",
            },
            {
              icon: Feedback,
              number: FIVE_ICON,
              alt: "Feedback",
              title: "Make some Tweaks as per the Feedback",
              description:
                "The designs are sent to the clients for thier feedback, and if there is a need to make some minor tweaks in the design, then it should be done.",
            },
          ],

          third_col: [
            {
              icon: Brainstorm,
              number: THREE_ICON,
              alt: "Brainstorm",
              title: "Brainstorm Ideas",
              description:
                "Our entire designing team does the brainstorming session to enhance the basic idea and share their opinions with the clients.",
            },
            {
              icon: Success,
              number: SIX_ICON,
              alt: "Success",
              title: "Wrap-up",
              description:
                "After finalizing the design of the flyers, logo, posters, or Brochures, it is again sent to clients.",
            },
          ],
        },
      },
      home: {
        header: {
          heading: "Designing Services",
          active_page: "Designing Services",
          alt: "Designing Services",
          image: Design_ST,
        },
        application: {
          sub: "Applications",
          main: "Designing Services Applications",
          list: [
            {
              title: "UI/UX<br> Designing",
              number: One,
              icon: CVision,
              link: "/ui-ux-designing",
            },
            {
              title: "Graphic<br> Designing",
              number: Two,
              icon: NLP,
              link: "/graphic-designing",
            },
          ],
        },
      },
    },
    digital_marketing: {
      header_image: DigitalMarketing,
      heading: "Digital Marketing",
      description:
        "At Ellocent Labs, we offer a wide range of design services that are focused on enhancing the visual appeal and user experience of your digital products. Our team of innovative minds combines creativity and technical expertise to deliver compelling and user-centric designs.<br><br>Website development is an industry that undergoes frequent changes. To feed the current requirements and trends of people, experts have to come up with different ideas to see what works the most stylishly according to the current situation. Then's where the game lies.<br><br>The success of an online business depends solely on its web operation as that's how people come to know about your business or brand which in turn increases your business deals and performance from time to time. From CMS websites to e-Commerce platforms, we will help you set- up effects the way you need for the successful growth of your business.",
      digital_service_list: [
        {
          service_icon: SEOHeader,
          service_heading: "Search Engine Optimization (SEO):",
          service_link: "/search-engine-optimization",

          service_des:
            "Our Search Engine Optimization services are aimed at improving your website's visibility on search engine results pages. We conduct in-depth keyword research, optimize your website's structure and content, and build authoritative backlinks to enhance its search engine presence. ",
        },
        {
          service_icon: SMOHeader,
          service_heading: "Social Media Optimization (SMO): ",
          service_link: "/social-media-optimization",

          service_des:
            "Social Media Optimization is all about harnessing the power of social media platforms to promote your brand, engage with your audience, and drive traffic to your website. Our SMO services involve creating and optimizing your social media profiles, developing a content strategy, and running targeted social media campaigns. ",
        },
        {
          service_icon: ORMHeader,
          service_heading: "Online Reputation Management (ORM)",
          service_link: "/online-reputation-management",

          service_des:
            "In today's digital world, your online reputation plays a crucial role in shaping the perception of your brand or business.  Our Online Reputation Management services help you monitor, protect, and enhance your brand's image online. We employ advanced tools and strategies to track mentions of your brand, address customer reviews and feedback, and manage any negative publicity effectively.",
        },
      ],

      seo: {
        header: {
          heading: "Search Engine Optimization",
          sub_parent: "Digital Marketing",
          sub_parent_link: "/digital-marketing",
          active_page: "Search Engine Optimization",
          alt: "Search Engine Optimization",
          headerimage: SEOHeader,
        },
        banner: {
          main: "We Deliver Success For Your Business With Our SEO Services",
          description:
            'If you are perplexed about your competitors\' top ranking on Google, increased sales, and growth, you are surely missing the boat of SEO. So, if you want to hit the target, you are at the right place because our team of digital marketers experts helps you take your online business to the next level of visibility. We are here to help you improve the organic search traffic and ranking on the search engine.<br><br>The Expert team of Ellocent Labs IT Solutions Pvt. Ltd. follows the deep research process to carry out the marketing strategies that your competitors miss to craft. Additionally, we follow the "<b>WHITE HAT SEO</b>" Practices by finding out the best & long-term keywords that fit best for your business to attract quality organic traffic. ',
          alt: "SEO",
          bannerimage: SEOBanner,
        },
        servicetitle: "Our Working Process",
        services: [
          {
            icon: Audit,
            title: "Audit",
            description:
              "The audit phase is critical for creating effective SEO strategies. Our marketing expert team audits the website to find out the existing flaws, like whether the pages are indexed, broken URLs, incorrect meta tags, meta descriptions, Alexa rank, wrong keywords, etc., and the barriers to success.",
          },
          {
            icon: Board,
            title: "Strategy",
            description:
              "The next important step is to craft a unique SEO strategy, like understanding the business goals, target audience, and keyword research. SEO is an ongoing process that needs to be revised as the algorithms used by search engines change constantly. So, with our <b>“Solid SEO Strategies,”</b> we can ensure that your website will be visible to your target audience.",
          },
          {
            icon: Shield,
            title: "Quality Check",
            description:
              "We always ensure that your website contains the quality keywords, content, titles, URL pages, enhances user experience and provides the information that searchers are looking for. We make your website a <b>“Well-Optimized SEO”</b> website.",
          },
          {
            icon: DocumentImage,
            title: "Reporting",
            description:
              "Our digital marketing experts provide SEO reports that give a birds-eye view of how your keywords are performing, ranking, organic traffic, visitors, and leads that convert.",
          },
        ],
        unique: {
          main: "What Makes Us Unique",
          icons: [
            {
              icon: Auditing,
              alt: "Auditing",
            },
            {
              icon: Business,
              alt: "Business",
            },
            {
              icon: Digital,
              alt: "Digital",
            },
            {
              icon: Start,
              alt: "Start",
            },
            {
              icon: Flexibility,
              alt: "Flexibility",
            },
            {
              icon: QualityWork,
              alt: "QualityWork",
            },
            {
              icon: Tracking,
              alt: "Tracking",
            },
            {
              icon: Transparency,
              alt: "Transparency",
            },
            {
              icon: Communication,
              alt: "Communication",
            },
          ],
        },
        hiring: {
          main: "Perks Of Hiring Us",
          image: SEOHiring,
          alt: "Hiring",
          list: [
            {
              title: "We grow our online presence and build Trust ",
            },
            {
              title: "Drive quality organic traffic",
            },
            {
              title: "Attract leads that convert",
            },
            {
              title: "Building Brand",
            },
          ],
        },
        icon: {
          icon: BlueCircleIcon,
        },
      },
      smo: {
        header: {
          heading: "Social Media Optimization",
          parent: "What We Do",
          sub_parent: "Digital Marketing",
          active_page: "Social Media Optimization",
          alt: "Social Media Optimization",
          headerimage: SMOHeader,
        },
        banner: {
          title: "The Wave Of Interactive Social Media Optimization Is Here.",
          description:
            "In the modern era, the social media wave is only getting bigger and more impactful for scaling the business. If you are struggling with the growth of your business, then you are on the right page. We at Ellocent Labs IT Solutions Pvt. Ltd. have years of experience in helping to build, scale, and create a brand identity on social media channels.<br><br>We have a team of SMO experts to create the best strategies that will help you to reach a larger audience. With our expertise, we create compelling content, graphics, videos, and characters that will entice your followers for a longer duration and rocket up the follower count. Additionally, our team of experts will ensure that the correct information related to the product or brand will be distributed on all the social media channels like;",
          alt: "Social Media Optimization",
          bannerimage: SMOBanner,
          icon: PlayButton,
          lastpara:
            "Furthermore, we always make sure that your brand’s image is in the right hands.",
          list: [
            {
              title: "Facebook",
            },
            {
              title: "Twitter",
            },
            {
              title: "Instagram",
            },
            {
              title: "Youtube",
            },
            {
              title: "Reddit & others",
            },
          ],
          cards: [
            {
              icon: Facebook,
              title: "Facebook Marketing",
              description:
                "Facebook has over 2.85 billion active monthly users. People use this platform to connect with family and friends and stay updated with current events. So our team of professionals can create impressive strategies for Facebook users as per their interests.",
            },
            {
              icon: Instagram,
              title: "Instagram marketing",
              description:
                "Instagram is the new wave in the digital world, allowing users to share photos and videos of their business accounts or personal. Several businesses are benefiting from this platform. So with our team of experts, you can also reap the benefits of our expert SMO marketing strategies.",
            },
            {
              icon: Twitter,
              title: "Twitter",
              description:
                "Our professional team of experts helps to take your business to the next level by increasing the follower base. We consistently post engaging content as per the marketing trends.",
            },
          ],
        },
        services: {
          main: "Our Social Media Services Include",
        },
        features: {
          main: "Our Work<br> Process",
          list: [
            {
              title: "Strategies",
              description:
                "Create the unique and key social media strategies that lay the foundation for accomplishing your business goals, including setting up the social sites, identifying the business opportunities, target audience, and type of content to share.",
            },
            {
              title: "Optimize Site & Profiles",
              description:
                "Creating a solid brand presence on social channels by completing the right profile information, adding call to action buttons and groups & displaying social links on websites.",
            },
            {
              title: "Advertise",
              description:
                "Post the content at the optimal time. The expert team of SMO finds the right time as per the target audience and which social site you are using.",
            },
            {
              title: "Track & Improve",
              description:
                "The professionals at Ellocent Labs IT Solutions track SMO with social media analytics. Besides, Shortened URLs can be used for social media campaigns. By tracking the strategies & results, we will improve the SMO strategies.",
            },
          ],
        },
        hiring: {
          main: "Perks Of Hiring Us",
          image: SMOHiring,
          icon: BlueCircleIcon,
          alt: "Hiring",
          list: [
            {
              title: "All in one Digital Marketing Team under one roof",
            },
            {
              title: "Keen eye on all the latest marketing trends",
            },
            {
              title: "Customize strategies",
            },
            {
              title: "Start-to-finish strategies",
            },
          ],
        },
      },
      orm: {
        header: {
          heading: "Online Reputation<br> Management",
          sub_parent: "Digital Marketing",
          active_page: "Online Reputation Management",
          alt: "Online Reputation Management",
          headerimage: ORMHeader,
        },
        banner: {
          title: "Level Up Your Business Reputation With Our ORM Services",
          description:
            "In this digital era, online reputation plays a key role in attracting business partners, winning customers, differentiating you from competitors, and also helps in retaining the old one. Additionally, it also helps in affecting decision-makers.<br><br>A positive online presence provides a competitive edge, a prominent factor for scaling the business and success. If you face an online reputation crisis, then our team of ORM takes it as a challenge and helps you build a positive impact online. In another scenario, if you want to take the proactive approach, we are also specialists. For this, we use the latest content creation and SEO development services to replace the negative presence on the search engine.<br><br>The experts of ORM at Ellocent Labs IT Solutions Pvt. Ltd. help you in your journey to ensure that your company or brand stands out only for positive reviews. Leave all your worries to us.",
          alt: "Our ORM Services",
          bannerimage: ORMBanner,
        },
        work_process: {
          heading: "Our Work Process",
          boxes: [
            {
              icon: Reputation,
              alt: "Reputation",
              heading: "Reputation Analysis",
              description:
                "The first phase of our work process is to conduct the online reputation management campaign with extensive online research through your business keywords, all positive and negative reviews related to your business, and identify potential threats. For this, we use all the quality and legal strategies.",
              list: [
                {
                  icon: BlueCircleIcon,
                  title: "Content and search result removal",
                },
                {
                  icon: BlueCircleIcon,
                  title: "Reputation analysis",
                },
                {
                  icon: BlueCircleIcon,
                  title: "Hashtag research",
                },
              ],
            },
            {
              icon: WebSetting,
              alt: "WebSetting",
              heading: "Web Property Development",
              description:
                "After the in-depth analysis, we move further with removing the flaws in your existing websites, building the business directories at reputable websites, etc. We bridge the gap by following methods.",
              list: [
                {
                  icon: BlueCircleIcon,
                  title: "Social media Profile setup",
                },
                {
                  icon: BlueCircleIcon,
                  title: "Blog",
                },
                {
                  icon: BlueCircleIcon,
                  title: "Image sharing website",
                },
              ],
            },
            {
              icon: ContentCreation,
              alt: "Content Creation",
              heading: "Content Creation",
              description:
                "Creating impactful content is one of the essential tasks for managing online reputation. The team of ORM experts creates compelling content for your owned property or third-party websites to improve your brand's online reputation by following strategies.",
              list: [
                {
                  icon: BlueCircleIcon,
                  title: "Blog for the third party",
                },
                {
                  icon: BlueCircleIcon,
                  title: "Social media post",
                },
                {
                  icon: BlueCircleIcon,
                  title: "Article submission",
                },
                {
                  icon: BlueCircleIcon,
                  title: "Executive bios and more",
                },
              ],
            },
            {
              icon: Promotion,
              alt: "Promotion",
              heading: "Publishing & Promotion",
              description:
                "We always make sure that the trending, informational & impactful content will be published on owned property. The next step is to promote the published content to reach a wider audience. In this way, our experts at Ellocent Labs IT Solutions Pvt. Ltd. build your business's positive online reputation.",
            },
          ],
        },
      },
      e_commerce: {
        header: {
          heading: "E-Commerce<br> Marketing Services",
          parent: "What We Do",
          sub_parent: "Digital Marketing",
          active_page: "E-Commerce Marketing Services",
          alt: "E-Commerce Marketing Sercices",
          headerimage: ECommerceHeader,
        },
        banner: {
          main: "E-Commerce Marketing Services",
          description:
            "Expert Formula that Up Your E-commerce Business The new technology keeps on evolving; that’s why in today’s digital era, the word of mouth publicity has become so outdated for promoting your e-commerce business. So, you must be thinking about what should be the solution for it? Right?<br> If you want to upscale your e-commerce business, then E-commerce marketing services play a crucial role in propelling your business success. Our E-commerce Marketing experts deploy holistic marketing strategies focused on Facebook, Instagram, Twitter, Youtube, and others.<br> To optimize the campaign strategies, we facilitate all the trending and impactful marketing strategies and keep track of every campaign, like how the engagement was and how many customers interacted with our team. All these e-commerce marketing strategies are carried out under one umbrella. ",
          alt: "E-Commerce Marketing Sercices",
          bannerimage: ECommerceBanner,
        },
        services: {
          main: "The Services We Provide",
          alt: "Facebook ad",
          bannerimage: ECommerceServices,
          list: [
            {
              title: "Social media marketing services",
            },
            {
              title: "Facebook ad ",
            },
            {
              title: "Search engine optimization services ",
            },
            {
              title: "Building Brand",
            },
          ],
        },
        hiring: {
          main: "Perks Of Hiring Us",
          alt: "Hiring",
          bannerimage: ECommerceHiring,
          list: [
            {
              title: "Full package of Digital Marketing",
            },
            {
              title: "Expertise and Experience",
            },
            {
              title: "Deep Analysis",
            },
            {
              title: "Competitor’s Research",
            },
          ],
        },
        icon: {
          icon: BlueCircleIcon,
        },
      },
      home: {
        header: {
          heading: "Digital Marketing",
          active_page: "Digital Marketing",
          alt: "Digital Marketing",
          image: Digital_ST,
        },
        application: {
          sub: "Applications",
          main: "Digital Marketing Applications",
          list: [
            {
              title: "Search<br> Engine Optimization",
              number: One,
              icon: CVision,
              link: "/search-engine-optimization",
            },
            {
              title: "Social<br> Media Optimization",
              number: Two,
              icon: NLP,
              link: "/social-media-optimization",
            },
            {
              title: "Online<br> Reputation Management",
              number: Three,
              icon: TimeSeries,
              link: "/online-reputation-management",
            },
            // {
            //   title: "E-Commerce<br> Marketing Services",
            //   number: Four,
            //   icon: DataAnalytics,
            //   link: "/e-commerce-marketing-services",
            // },
          ],
        },
      },
    },
    web_development: {
      sub_parent: "Web Development",
      sub_parent_link: "/web-development",
      heading: " Web Development",
      description:
        "At Ellocent Labs, we offer a range of specialized web development services to help businesses establish a strong online presence and achieve their digital goals. Our team of experienced developers is well-versed in cutting-edge technologies and industry best practices to deliver high-quality solutions that meet your business’s specific requirements.<br><br>Web development services involve the design,  creation, and maintenance of websites and web applications to meet the unique needs of businesses and organizations. These services encompass a wide range of activities, including front-end development, back-end development, database management, server configuration, and security implementation.<br><br>Web development professionals utilize various programming languages, frameworks, and tools to build responsive, user-friendly, and visually appealing websites that deliver a seamless user experience. By understanding clients' requirements and objectives, web development services aim to create robust and scalable online platforms that effectively represent their brand, engage users, and achieve their business goals.",
      progressive_app: {
        header: {
          heading: "Progressive App Development",
          sub_parent: "Web Development",
          sub_parent_link: "/web-development",
          active_page: "Progressive App Development",
          alt: "Progressive App Development",
          headerimage: ProgressHeader,
        },
        banner: {
          title: "What Is Progressive App Development?",
          description:
            "Progressive Web App is the advanced solution for pulling the plug on the shortcomings of native apps and mobile apps. A PWA Development is a website designed to look like an application that runs on your laptop, mobile device, desktop, or tablet. So, we can say that this smart and advanced solution simulates the behaviour of native mobile apps and also, there is no need for an app installation store. Which means it provides a rich offline experience.<br><br>Ellocent Labs IT Solutions Pvt. Ltd. is an emerging company that provides progressive web app development services by delivering fast, reliable, and highly responsive web apps tailored to diverse clients' global needs. Additionally, we are also ensuring our customers about delivering quality, increased conversion, highly-visual content and enhanced user experience journey. Furthermore, as a leading progressive web app Development Company, our services help our clients to boost their sales and user engagement which builds brand loyalty in the cut-throat business world.<br><br><b>Glance:</b>",
          alt: "Progressive App Development",
          bannerimage: ProgressBanner,
          list: [
            {
              title:
                "The popularity of PWA development services keeps on rising.",
            },
            {
              title:
                "Shopify, Tinder, Uber, Forbes and many others are the users of this.",
            },
            {
              title: "Crafting enterprise-grade feature-rich PWA.",
            },
          ],
        },
        features: {
          main: "Features Of Progressive Web App",
          list: [
            {
              title: "Work Offline",
              description:
                "The beauty of PWA is that it works efficiently with low internet speed, and it also consumes the least amount of data. It can also work in offline mode.",
            },
            {
              title: "Swift",
              description:
                "This bespoke PWA is lighter than the native app, which means it takes a short loading time, is high- responsive, and has no tedious scrolling.",
            },
            {
              title: "Smooth User Experience",
              description:
                "The interface is intuitive, which provides a smooth user experience without any lags on laptops, tablets, and smartphones. These are easy to install and can be shared via the URL.",
            },
            {
              title: "Highly Responsive",
              description:
                "Our repository of skilled developers builds the robust and highly secure PWA that renders seamless interactivity across all browsers and devices.",
            },
          ],
        },
        expertise: {
          main: "Our Technical Expertise",
          technical: [
            {
              image: JavascriptImg,
              alt: "javascript",
              title: "JAVASCRIPT",
            },
            {
              image: HTMLImg,
              alt: "html",
              title: "HTML",
            },
            {
              image: CSSImg,
              alt: "css",
              title: "CSS",
            },
          ],
        },
        significant: {
          title: "The Significant Perks Of Choosing The ELIS",
          description:
            "We believe in providing quality work, and the secret sauce behind our accomplished commitment is our Progressive web app development experts, always covering the extra mile to deliver stellar results.",
          bannerimage: Significant,
          alt: "Professional documentation",
          list: [
            {
              title: "Quick assistance from our experts",
            },
            {
              title: "No fake commitments",
            },
            {
              title: "Deliver the project before the deadlines",
            },
            {
              title: "Transparent communication",
            },
            {
              title: "Professional documentation",
            },
            {
              title: "Highly secure PWA",
            },
          ],
        },
        icon: {
          icon: BlueCircleIcon,
        },
      },
      customer: {
        header: {
          heading: "Custom Web Development",
          active_page: "Custom Web Development",
          alt: "Custom Web Development",
          headerimage: CustomerHeader,
        },
        banner: {
          title: "What Is Custom Web Development?",
          description:
            "An efficient custom web development service aims to provide the golden opportunity for your business to make a positive and solid digital reputation in this digital realm. If you are searching for your perfect match that will provide innovative and engaging solutions for all your digital needs and help in leveraging the competitive edge, then Ellocent Labs IT Solutions Pvt. Ltd. will get the job done for you with the right work approach.<br><br>Ellocent Labs IT Solutions Pvt. Ltd. is a world-renowned company that has successfully accomplished various custom web development projects for diverse clients globally. Additionally, we have a specialization in developing interactive, scalable, and highly responsive custom web solutions. Our vision is to provide quality and profitable solutions that will spark and boost the ROI.<br><br>We have a team of professional developers who hold a great hand over the tailored made creation from scratch using the front-end and back-end technologies.",
          alt: "custom web development",
          bannerimage: CustomerBanner,
        },
        features: {
          title: "Unique Features Tailored To Your Business Needs?",
          description:
            "The major drawback of website builder templates is that it is constrained by the themes & functionality. It means your website will look like thousands of other websites with no unique touch. On the other hand, if we talk about custom web development services, there is no limitation on their look or how the structured elements are organized. Besides, your website has a unique look and feel for the target audience.",
        },
        ellocent: {
          title:
            "What Makes Ellocent Labs IT Solutions Different From Other Website Design & Development Companies?",
          description:
            "Till now, we have been delivering our custom website development services from global leaders to entrepreneurs, from startups to big companies, by tailoring our process based on the business scale and requirements. Our dexterous team of developers always thinks big and delivers results according to market trends. We provide the services by keeping in mind these key points.",
          cards: [
            {
              id: "01",
              title: "Proven Track-Record",
              description:
                "We have a proven track record of developing a custom website for diverse business needs and clients globally, making us extensively experienced and masters of our field.",
            },
            {
              id: "02",
              title: "Streamlined Communication",
              description:
                "We are committed to delivering quality services by sharing work updates or any business-related concerns with the clients using project management tools like Jira, Asana, etc.",
            },
            {
              id: "03",
              title: "Result-Driven Approach",
              description:
                "Our development team of professionals has a keen eye on the minor details of the project, which is an essential step in saving time and money on significant changes that occur in the project.",
            },
            {
              id: "04",
              title: "Agile Approach",
              description:
                "We develop projects based on agile methodologies that take away the unnecessary spending time on the task that will not add any value to the project.",
            },
          ],
        },
      },
      cms: {
        header: {
          heading: "CMS Development",
          active_page: "CMS Development",
          alt: "CMS Development",
          headerimage: CMSHeader,
        },
        about_cms: {
          title: "What Is CMS Development?",
          description:
            "As the name suggests, with Content Management System, users have the privilege to create, manage, and edit the website content without having any technical expertise or knowledge. Besides, suppose you think that CMS Development services only help manage the text or images. In that case, you are wrong because here, users also have the privilege of tracking the interactive user sessions, handling the search queries, and collecting visitor feedback and comments.<br><br> With Ellocent Labs IT Solutions Pvt. Ltd., you have unlimited access to its skilled professionals and state-of-the-art software solutions on hot trending technologies. We follow the industry's best practices to build secure and scalable CMS solutions for diverse business domains with our in-depth knowledge. Additionally, we have a couple of years of experience developing Open-source CMS platforms, including wordpress and others.",
        },
        banner: {
          Glance: "<b>Glance:</b>",
          alt: "CMS solutions",
          bannerimage: CMSBanner,
          icon: BlueCircleIcon,
          list: [
            {
              title: "Our CMS solutions are SEO friendly and result-driven.",
            },
            {
              title:
                "Equipped with resources of dedicated developers and designers",
            },
            {
              title: "We offer an exceptionally smooth user experience.",
            },
            {
              title:
                "Ease of maintenance & seamless integration of the latest updates",
            },
            {
              title:
                "Provides the highly secure and performance-oriented solutions",
            },
          ],
        },
        expertise: {
          heading: "Our Technical Expertise",
          box: [
            {
              icon: Wordpress,
              alt: "wordpress",
              title: "Wordpress",
            },
            {
              icon: bubble,
              alt: "bubble",
              title: "Bubble",
            },
            {
              icon: webflow,
              alt: "webflow",
              title: "Webflow",
            },
          ],
        },
        offer: {
          heading: "What We Offer",
          description:
            "We provide end-to-end CMS Development Services tailored to the needs and specifications of every business vertical. We offer a full suite of CMS development services ranging from design to development, optimization, and maintenance.",
          first_col: [
            {
              icon: CMSServices,
              alt: "cmsservices",
              title: "CMS Development Services",
              description:
                "Our CMS developers have rich expertise in developing the desired solutions as per the client’s needs by implementing the agile approach.",
            },
          ],
          second_col: [
            {
              icon: CustomTheme,
              alt: "customtheme",
              title: "Custom Theme Integration",
              description:
                "We craft solutions that are a perfect match for your business domains with a smooth user experience and SEO friendly features.",
            },
            {
              icon: Vision,
              alt: "vision",
              title: "Support & Maintenance",
              description:
                "We are not only here to provide Development services, but also we are pro in offering support and maintenance to your CMS solutions.",
            },
          ],
          third_col: [
            {
              icon: CMSPlugins,
              alt: "csmplugins",
              title: "CMS Plugins & Integration",
              description:
                "Expertise in covering the flaws of your website with our expertise, like Integrating desired plugins and implementing add-ons to new & improved websites.",
            },
          ],
        },
        solutions: {
          heading: "Your CMS Solutions Will Be Faster With Us",
          image: CMSSolution,
          alt: "csmsolution",
          description:
            "Ellocent Labs IT Solutions Pvt. Ltd. is a pioneer in developing CMS solutions for various business domains like Real Estate, Health Care, Cryptocurrency, E-Learning, Tour & Travel, etc. The secret sauce behind our fastest delivery is the tech brain of our professional team and agile work approach. Till now, Our tech team has showcased stellar performance in developing numerous solutions with great customizable features, modules, plugins, and extensions in Woocommerce and WordPress platforms.",
        },
      },
      bubble: {
        header: {
          heading: "Bubble",
          active_page: "Bubble",
          alt: "Bubble",
          headerimage: BubbleHeader,
        },
        banner: {
          title: "What Is No-Code?",
          description:
            "In today’s modern world, No-code is one of the best agile approaches for developing software or a website without having expertise with the various programming languages. Which means, it gives the privilege to non-programmers to design a workable application by dragging and dropping the different elements like sign-up fields, image galleries, user profiles etc. for the applications. Here, in no-code, the app is built visually, without the need to write the code in the backend.<br><br> Our company is one of the prominent companies for providing the no-code development services and helps multiple businesses launch their websites quickly and efficiently in a cost effective manner.<br><br> Our seasoned developers are familiar with all the essential requirements for developing the MVP of any major app or website so that it can launch in the market among the target audience and can grow exponentially by striking a new sales record.",

          // Glance: "<b>Glance:</b>",
          alt: "CMS solutions",
          bannerimage: BubbleBanner,
          // icon: BlueCircleIcon,
        },
        invest: {
          main: "Bubble",
          alt: "Bitcoins",
          src: BubbleInvest,
          description:
            "No-code platforms like Bubble allows users to create a fully-functional website by using a drag and drop interface. Which allow users to design and build applications visually, using pre-built blocks and templates. Which simply means that a user can create a complex app or website up to 10 times faster than the coding.",
        },
        benefits: {
          main: "Benefits Of Bubble",
          cards: [
            {
              icon: Webpage,
              description:
                "<b>Full-control:</b> No-code tools like Bubble offer a new level of advancement in website and mobile applications. It's faster, cheaper, and rewards the users by giving them a full control over design, functionality, and extensibility of the software.",
            },
            {
              icon: economic,
              description:
                "<b>Economical:</b> Since, no-code doesn’t require any extensive lines of programming coding because it already has pre-building blocks which saves time and helps in cutting the cost. Additionally, the post development tweaks and updates will also be done quickly if it’s not complex and time consuming.",
            },
            {
              icon: speedometer,
              description:
                "<b>Speed:</b> One of the prominent features of the Bubble development is the speed to deployment. Additionally, the visual interface and pre-built templates and blocks allow the creation of the website quickly and seamlessly.",
            },
            {
              icon: scalability,
              description:
                "<b>Scalability:</b> The next striking feature of this platform is, it allows scalability as the business grows. Business’s growth is directly proportional to the increase in demand of the requirement. It offers many features like database integration, workflow automation, and other third-party integration, making it much easier to add on the new features.",
            },
          ],
        },
        tech_expertise: {
          main: "Quick Reasons to Pick a Bubble",
          button: "Explore More",
          icon: BlueCircleIcon,
          image: BubbleQuick,
          alt: "Hiring",
          list: [
            {
              title: "User-authentication",
            },
            {
              title: "Database Integration",
            },
            {
              title: "Responsive Design",
            },
            {
              title: "Third Party Integration",
            },
            {
              title: "Workflow Automation",
            },
          ],
        },
      },
      backend: {
        header: {
          headerimage: BackendHeader,
          heading: "Backend Development",
          active_page: "Backend Development",
          alt: "Backend Development",
        },
        banner: {
          title: "What Is Backend Development?",
          description:
            "Backend development is the most important part of the software, or we can say that it’s like a brain for the software, even though it’s hidden under the hood. Without an efficient backend, the software will not be able to perform the functionality as intended.<br><br>Ellocent Labs IT Solutions Pvt. Ltd. has years of experience in providing professional backend development solutions tailored to the needs of individual clients. Our expert team is always ready to face new challenges at the workstation. Additionally, we always have a keen eye on all the trending technologies, that’s why we never miss a boat to enhancing our skills.<br><br><b>Glance:</b>",
          alt: "Backend Development",
          bannerimage: BackendBanner,
          icon: BlueCircleIcon,
          list: [
            {
              title:
                "Expertise in coherent software solutions to perform the designated task.",
            },
            {
              title:
                "Follow the latest security protocols for keeping your data safe",
            },
            {
              title:
                "Deliver the scalable solutions for the exponential growth of your business",
            },
            {
              title: "Implementing the advanced and time-tested technologies",
            },
          ],
        },
        expertise: {
          main: "Our Technical Expertise",
          list: [
            {
              id: "01",
              title: "Server",
            },
            {
              id: "02",
              title: "Data Base",
            },
            {
              id: "03",
              title: "Backend Programming Language",
            },
            {
              id: "04",
              title: "Application Programming Interface",
            },
          ],
        },
        benefits: {
          heading: "Benefits Of Backend Development",
          description:
            "Our efficient backend development team knows how to write, optimize, and deploy intricate server-side code. Additionally, they are well-versed in writing complex functional protocols and building intricate libraries.",
          alt: "Benefits Of Backend Development",
          bannerimage: BackendBenefits,
          icon: BlueCircleIcon,
          list: [
            {
              title: "Full-Cycle Backend Development",
            },
            {
              title: "End -to End security",
            },
            {
              title: "Quality code",
            },
            {
              title: "Cross-Platform Development",
            },
          ],
        },
        factors: {
          heading: "Factors That Set Us Apart In Backend Development Services",
          first_col: [
            {
              icon: CustomerReview,
              alt: "icon",
              title: "In-depth Expertise",
              description:
                "We have a quick-witted team of backend developers with in-depth expertise and experience that lead them to complete the challenging task of backend development efficiently.",
            },
          ],
          second_col: [
            {
              icon: Agile,
              alt: "icon",
              title: "Agile Principles",
              description:
                "We have implemented agile methodologies for timely delivery and quality work by reducing the overall risk involved in development.",
            },
            {
              icon: QualityCode,
              alt: "icon",
              title: "Clean & Quality Code",
              description:
                "We work on the SOLID principles of coding; that’s why we write quality and clean code that the clients can easily understand and maintain after completion.",
            },
          ],
          third_col: [
            {
              icon: Secure,
              alt: "icon",
              title: "Highly Secure",
              description:
                "Our excellent team of professionals knows how to develop highly secure features for the unhackable backend.",
            },
          ],
        },
      },
      full_stack: {
        header: {
          heading: "Full Stack Development",
          active_page: "Full Stack Development",

          alt: "Full Stack Development",
          headerimage: FullStackHeader,
        },
        banner: {
          title: "What Is Full Stack Development?",
          description:
            "Ellocent Labs IT Solutions Pvt. Ltd. has been helping various businesses fulfill their business requirements by providing them with a complete suite of various services like website development, Mobile app development, Progressive web app development, CMS development and others. In addition, our professionals at Ellocent Labs IT Solutions company have a wide range of experience and understanding in developing full-fledged robust solutions with front-end, backend frameworks and database management.<br><br><b>Glance:</b>",
          alt: "Full Stack Development",
          bannerimage: FullStackBanner,
          icon: BlueCircleIcon,
          image: Web_ST,
          list: [
            {
              title:
                "Proficient in crafting the feature-rich solutions that perfectly match your vision",
            },
            {
              title: "Secure, stable and fast backend",
            },
            {
              title: "In-depth knowledge of technologies",
            },
            {
              title: "Expertise in handling the complex projects",
            },
          ],
        },
        expertise: {
          main: "Our Technical Expertise",
        },
        benefits: {
          title: "Benefits Of Full Stack Development",
          description:
            "Full stack developers are skilled enough in building the modern solutions by understanding your needs and vision. For this, they will efficiently carry out the research process, identify the risk factors involved by cutting the operational project cost.",
          alt: "Benefits Of Full Stack Development",
          sub: "Our Tools Of Trade",
          src: FullStackBenefits,
          icon: BlueCircleIcon,
          list: [
            {
              title: "Comprehensive solution",
            },
            {
              title: "Faster development",
            },
            {
              title: "Increase productivity",
            },
            {
              title: "Extremely secure",
            },
          ],
        },
        services: {
          title: "Why Clients Prefer Our Full Stack Development Services?",
          description:
            "The team of professional developers at Ellocent Labs IT Solutions Pvt. Ltd. take the full charge of delivering the frontend and backend development solutions with zero compatibility issues.",

          service_list: [
            {
              service_icon: FullStackHeader,
              service_heading: "Full Stack Development",
              service_link: "/full-stack-development",

              service_des:
                "Our Full Stack Development services encompass both frontend and backend development, offering end-to-end solutions for your web applications. Our talented developers have expertise in various programming languages, frameworks, and databases, allowing us to handle every aspect of your project.",
            },
            {
              service_icon: BackendHeader,
              service_heading: "Backend Development",
              service_link: "/backend-development",

              service_des:
                "Backend Development forms the foundation of any web application, encompassing server-side programming and database management. Our backend developers excel in languages such as Python, PHP, and Node.js, along with frameworks like Django, Laravel, and Express.js.",
            },
            {
              service_icon: CMSHeader,
              service_heading: "CMS Development",
              service_link: "/cms-development",

              service_des:
                "Content Management Systems (CMS) allow you to manage and update website content effortlessly, even without technical expertise. Our CMS Development services empower you to take control of your website's content, making updates and additions with ease.  ",
            },
            {
              service_icon: ProgressHeader,
              service_heading: "Progressive App Development",
              service_link: "/progressive-app-development",

              service_des:
                "In today's mobile-first world, Progressive Web Apps (PWAs) have gained tremendous popularity. Our Progressive App Development services enable you to create highly engaging and feature-rich web applications that work seamlessly across all devices. ",
            },
            {
              service_icon: BubbleHeader,
              service_heading: "No-Code Development",
              service_link: "/bubble",

              service_des:
                "A no-code; bubble is a visual programming platform that allows for rapid application development without extensive coding. Our team specializes in Bubble Development, creating powerful web applications using this intuitive platform.",
            },
            {
              service_icon: CMSHeader,
              service_heading: "Custom Web Development",
              service_link: "/custom-web-development",
              service_des:
                "Our Custom Web Development services are designed to meet your unique requirements and provide tailored solutions that perfectly align with your business goals. We believe in creating websites and web applications that not only look visually stunning. ",
            },
          ],
          cards: [
            {
              icon: Vision,
              title: "Crafting a way for your vision",
              description:
                "Clients’ can easily share their ideas with our professional team of developers. Our team of professionals conducts the brainstorm session and draws the way for delivering an efficient solution.",
            },
            {
              icon: CustomTheme,
              title: "Incorporating the required features",
              description:
                "Once the dedicated layout is ready for the client's idea then our development team will start the coding to give it a responsive and functional feel by adding the features.",
            },
            {
              icon: CMSPlugins,
              title: "Scalability",
              description:
                "We believe in providing scalable solutions so that if your business will grow exponentially, then the respective software should be able to handle the volume of data without any hindrance.",
            },
          ],
        },
      },
      home: {
        header: {
          heading: "Web Development",
          active_page: "Web Development",
          alt: "Web Development",
        },
        application: {
          sub: "Applications",
          main: "Web Development Applications",
          list: [
            {
              title: "Full Stack<br> Development",
              number: One,
              icon: CVision,
              link: "/full-stack-development",
            },
            {
              title: "Backend<br> Development",
              number: Two,
              icon: NLP,
              link: "/backend-development",
            },
            {
              title: "CMS<br> Development",
              number: Three,
              icon: TimeSeries,
              link: "/cms-development",
            },
            {
              title: "Customer<br> Web Development",
              number: Four,
              icon: DataAnalytics,
              link: "/custom-web-development",
            },
            {
              title: "Progressive<br> App Development",
              number: Five,
              icon: DataLearing,
              link: "/progressive-app-development",
            },
          ],
        },
      },
    },
    major_services: {
      sub_parent: "Major Services",
      sub_parent_link: "/major-services",

      bot: {
        header: {
          headerimage: BotHeader,
          heading: "BOT Development",
          active_page: "BOT Development",
          alt: "BOT Development",
        },
        banner: {
          title: "Bot Development Services",
          description:
            "Finally, the age of intelligent machines has arrived, and conversational interfaces are leading the charge. Bots are automated hardware or software machines powered by Artificial intelligence development services. Additionally, chatbots are software programs that can simulate human conversation. Besides they are also known by the name of Virtual agents, Virtual assistants, and Bots. The human can ask any question to a chatbot program, but before that, programmers first have to be fed the right information, which is known as the chatbot training phase.",
          servicealt: "Bot Development Services",
          gamealt: "Game Changers",
          secondtitle: "Game Changers For The Enterprise",
          bannerimage: BotBanner,
          secondbannerimage: BotSecondBanner,
          secondpara:
            "Additionally, these days every industry is disrupted by bot development which means bots have been implemented in multiple applications. A customer service bot in banking can assist the customer with fund transfers or loss of checkbooks.",
        },
        expertise: {
          sub: "Our Multi Vertical Expertise",
          main: "Recent Developments",
          icon: BlueCircleIcon,
          para1:
            "Time has changed drastically because new devices have paved their way into our everyday lives.",
          para2:
            "So whether you are looking for chatbot consultants or chatbot developers, then Ellocent Labs IT Solutions Pvt. Ltd. has a talent pool of developers who know AI/ML techniques, understand NLP engines, and code with Node.JS, Javascript, and Java. Till now, many bot development projects are under our belt; our proficient bot development team has much experience with rolling out highly intelligent bot development solutions.",
          para3:
            "A survey conducted by Hubspot states that 47% of consumers are open to buying items through chatbots, and around 40% don’t care whether they talk to humans or chatbots as long as they get help easily and quickly.",
          list: [
            {
              title:
                "In <b>2011</b>, Apple released an intelligent assistant called Siri as a part of their iPhones. It acts as a user’s personal assistant, like making calls, reading messages, and setting alarms and reminders.",
            },
            {
              title:
                "In <b>2011</b>, IBM introduced Watson, a question answering system.",
            },
            {
              title:
                "In <b>2013</b>, Microsoft introduced Cortana as a standard feature of Windows phones.",
            },
            {
              title:
                "<b>2014</b> was the year when Amazon launched the personal assistant the name “Alexa”.",
            },
          ],
        },
        choose: {
          title: "Why Choose Ellocent Labs IT Solutions Pvt. Ltd.?",
          alt: "Interactive UI/UX designs",
          bannerimage: ChooseBotBanner,
          icon: TickIcon,
          list: [
            {
              title: "Requirement Gathering",
            },
            {
              title: "Faster development",
            },
            {
              title: "Interactive UI/UX designs",
            },
            {
              title: "Seamless backend integration",
            },
            {
              title: "Smooth deployment",
            },
          ],
        },
      },

      major_service_list: [
        {
          service_icon: AIHeader,
          service_heading: "Artificial Intelligence (AI)",
          service_link: "/artificial-intelligence",

          service_des:
            "AI involves developing intelligent systems capable of performing tasks that typically require human intelligence. These services include natural language processing, computer vision, machine learning, and robotics. ",
        },
        {
          service_icon: MLHeader,
          service_heading: "Machine Learning Development",
          service_link: "/machine-learning",

          service_des:
            "Machine learning is a subset of AI that focuses on training algorithms to learn patterns and make predictions or decisions without explicit programming. Machine learning development services involve building and implementing machine learning models for various applications, such as predictive analytics, and image recognition. ",
        },
        {
          service_icon: BigDataHeader,
          service_heading: "Big Data Development",
          service_link: "/big-data",

          service_des:
            "Big data development involves processing and analyzing large volumes of structured and unstructured data to extract valuable insights. This service includes data ingestion, storage, processing, Spark, and NoSQL databases.",
        },
        {
          service_icon: ProgressHeader,
          service_heading: "IoT App Development",
          service_link: "/internet-of-things",

          service_des:
            "  IoT (Internet of Things) app development revolves around creating applications that interact with and control IoT devices. These services include developing mobile apps, backend systems, and connectivity protocols for seamless communication between devices and users.",
        },
        {
          service_icon: CryptoHeader,
          service_heading: "Cryptocurrency",
          service_link: "/cryptocurrency",

          service_des:
            " Cryptocurrency services involve the development and implementation of digital currencies based on blockchain technology. These services include creating decentralized payment systems, smart contracts, tokenization, and wallet development. ",
        },
        {
          service_icon: AWSHeader,
          service_heading: "AWS Cloud Solutions",
          service_link: "/aws-cloud-services",

          service_des:
            "AWS (Amazon Web Services) cloud solutions involve leveraging the infrastructure and services provided by Amazon's cloud computing platform. These services include deploying scalable and reliable applications, storage solutions,  serverless computing, and machine learning on the cloud.",
        },
        {
          service_icon: DataScHeader,
          service_heading: "Data Science",
          service_link: "/data-science",

          service_des:
            "Data science services encompass the extraction, analysis, and interpretation of data to gain insights and support decision-making. This involves data exploration, preprocessing, statistical modeling, and machine learning techniques.",
        },
        {
          service_icon: BotHeader,
          service_heading: "Bot Development Services",
          service_link: "/bot-development",

          service_des:
            "Bot development services involve creating chatbots or virtual assistants that can interact with users, answer queries, and automate tasks. These services include natural language processing, conversational AI, and integration with messaging platforms.",
        },
      ],
      data_sci: {
        header: {
          heading: "Data Science",
          active_page: "Data Science",
          alt: "Data Science",
          headerimage: DataScHeader,
        },
        banner: {
          title: "What Is Data Science ?",
          description:
            "The entire process of extracting useful information from unstructured data using ideas like statistical analysis, data analysis, machine learning techniques, data modelling, data preparation, etc. is known as data science. Data mining, forecasting, machine learning, predictive analytics, statistics, and text analytics are typically included in this method. The race is on for businesses to use the insights in their data as data is expanding at an alarming rate. To identify insights and investigate problems that the company wasn't even aware it had, most organisations need professionals who can analyse their big data. Organizations must integrate predictive insights, forecasting, and optimization methods into their business and operational systems in order to recognise and capitalise on the value of data science.",
          alt: "Data Science",
          bannerimage: DataScBanner,
        },
        significance: {
          description:
            "Data science has advanced significantly over the past several years and now affects almost every sector of the economy that produces or uses data. Data scientists combine the skills of software programmer, statistician, and storyteller/artist to harvest the nuggets of wealth hidden under mountains of data, according to Kenneth Cukier in an article from The Economist from 2010.Today, firms are prepared to pay top dollar to hire data scientists since they are so valuable to any organization where they work.",
          subheading: "What Data Science Offers",
          title: "Opportunities In Data Science",
        },
        responsibilities: {
          main: "Data Scientist Responsibilities And Duties",
          alt: "Data Scientist Responsibilities",
          src: DataScVector,
          icon: BlueCircleIcon,
          list: [
            {
              title: "Extraction, loading, and transformation of data",
            },
            {
              title: "Analyzing Exploratory Data",
            },
            {
              title: "Manipulation of data",
            },
            {
              title: "Analytical Statistics",
            },
            {
              title: "Visualization",
            },
            {
              title: "Data modelling",
            },
            {
              title: "Obtaining Useful Information",
            },
          ],
        },
      },
      aws_cloud: {
        header: {
          headerimage: AWSHeader,
          heading: "AWS/Cloud Services",
          active_page: "AWS/Cloud Services",
          alt: "AWS/Cloud Services",
        },
        banner: {
          title: "What Is AWS/Cloud Services?",
          description:
            "One of the most widely used cloud computing platforms is AWS (Amazon Web Services), which is used by several businesses. The majority of organisations have done so, and more are about to. Because of the cloud's many advantages that make it possible for businesses to store their data conveniently and without a physical location, the majority of businesses are moving their operations there. Data access has also become simpler because it is now possible to use the Internet at any time and from any location. One of the most sought-after professions today is AWS certified professionals.AWS is a cloud computing platform that offers a variety of services with the goal of allowing businesses to grow. They can manage, store, and safeguard their business data using this platform from illegal access.",
          alt: "AWS/Cloud",
          bannerimage: AWSBanner,
        },
        significance: {
          description:
            "We have to question what comes next as we migrate more and more workloads to AWS and rewrite programmes to enable smooth integration with all of the capabilities AWS provides. Cloud adoption is widespread among businesses of all sizes and has already become the new norm. In the coming years, we'll focus on optimizing present workloads and abstracting away as much of the day-to-day labour as we can. The previous few years have been about getting everyone on board. With the help of services like AWS Lambda, which let you run code without managing servers, progress has already been made in that direction.Many innovations in areas like AI, IoT, and blockchain will affect all advancements rather than just one use-case.",
          subheading: "Our Multi Vertical Expertise",
          title: "Future Of AWS",
        },
        services: {
          main: "The Services We Provide",
          alt: "AWS Services",
          icon: BlueCircleIcon,
          src: ToolsOfTrade,
          list: [
            {
              title: "Scalability and Flexibility",
            },
            {
              title: "Mobile, Web, and Social Applications",
            },
            {
              title: "Gaming",
            },
            {
              title: "Storage and Backup",
            },
            {
              title: "Big Data",
            },
            {
              title: "Security and Reliability",
            },
          ],
        },
      },
      cryptocurrency: {
        header: {
          heading: "Cryptocurrency ",
          active_page: "Cryptocurrency",
          alt: "Cryptocurrency",
          headerimage: CryptoHeader,
        },
        banner: {
          main: "What Is Cryptocurrency?",
          para1:
            "Cryptocurrency is decentralized digital money especially designed to be used over the internet. It's a peer to peer system that can enable anyone to send and receive money anywhere. Thus, cryptocurrency is purely based on digital transactions. When the user transfers cryptocurrency funds, the transactions are recorded in the public ledger, and our team of professional developers are proficient in developing cryptocurrency solutions for diverse business domains.",
          alt: "Cryptocurrency",
          bannerimage: CryptoBanner,
          icon: BlueCircleIcon,
          list: [
            {
              title:
                "Cryptocurrency runs on a distributed public ledger called cryptocurrency, whose access is distributed among other authorized users.",
            },
            {
              title:
                "This ledger records all the transactions, and the access is shared between its users, and all the shared information is transparent, immediate, and immutable.",
            },
            {
              title:
                "The information can not be tempered by anyone, not even by the administrators.",
            },
          ],
        },
        significance: {
          description:
            "Cryptocurrencies are not controlled by the government or central regulators. It is generated through a mining process in which miners have to solve complex mathematical puzzles over specially equipped computer systems to be rewarded with bitcoins in exchange. Users can easily buy Cryptocurrencies from the central exchange, brokers, and individual currency owners and sell them. Our Cryptocurrency Development Services allow users to invest in this trending technology. Bitcoins can be easily transferred from one digital wallet to another.",
          title: "How Does Cryptocurrency Work?",
        },
        invest: {
          sub: "Invest In Cryptocurrency",
          main: "Why Should Your Business Invest In Cryptocurrency?",
          alt: "Bitcoins",
          src: BitcoinImg,
          description:
            "<b>Reduced Transaction Expenses:</b> It can reduce the cost of doing business by eliminating the role of third parties. Crypto allows you to make and receive payments from clients without any intermediary. Besides, the transaction fee is also very low.<br>",
          description2:
            "<b>A Decentralized Form of Payment:</b> There is no governing body tracking or storing the transaction history.<br>",
          description3:
            "<b>Secure Technology:</b> The main technology behind Cryptocurrency is Blockchain, a distributed ledger which can not be tempered and manipulated easily.",
        },
      },
      iot: {
        header: {
          heading: "Internet of Things (IOT)",
          active_page: "Internet of Things (IOT)",
          alt: "Internet of Things (IOT)",
          headerimage: IOTHeader,
        },
        banner: {
          main: "What Is Internet Of Things?",
          description:
            "Ellocent Labs IT Solutions Pvt. Ltd. is an emerging company developing IoT softwares and applications for diverse business needs. So whether you belong to the Healthcare industry or automotive industry, we got all covered for you.<br><br> The internet of things network comprises smart devices-web enabled. It uses processors, software, and communication hardware to receive, process, and send data from connected devices. After that, these IoT Devices share the data from their connections to the IoT gateway through which data is further sent to the cloud or further channels for analysis.",
          alt: "Internet Of Things",
          bannerimage: IOTBanner,
        },
        significance: {
          description:
            "The Ellocent Labs IT Solutions Pvt. Ltd. team understands that every business is unique, so thus their requirements are also unique. So we provide solutions that match the business requirements and expectations. Besides, consistently gathering data and information that is reliable and useful requires the adoption of smarter technology. So all you need to get connected with the experts of Ellocent Labs IT Solutions Pvt. Ltd.",
          subheading: "The Role Of Internet Of Things",
          title: "Our IoT Development Services For All Industries",
        },
        offertitle: {
          sub: "Our Multi Vertical Expertise",
          main: "What We Offer",
        },
        offer: [
          {
            src: Smartwatch,
            alt: "smartwatch",
            title: "Smart Device <br>Management",
            description:
              "With the integration of IoT Development solutions and connected ideas, our professional team of experts has hands-on experience developing wearables or smart devices. These smart device development services enhance communication and work quality.",
          },
          {
            src: HomeAutomation,
            alt: "home automation",
            title: "Home Automation Solutions",
            description:
              "Our professional team of IoT developers utilizes advanced technologies to create an environment-friendly smart home. Besides, our all smart solutions render you with smart living options from turning on the light to locking the house. Moreover, all home appliances are connected with a wearable device that will give you enough time to focus on other things by leaving technical worries to our smart experts.",
          },
          {
            src: Smartphone,
            alt: "samrtphone",
            title: "GPS Tracking<br> Band",
            description:
              "It’s a GPS device with an integrated GPS receiver, which is light, harmless to health, flexible, and easy to use. You can tie this GPS tracking band to your child’s wrist, and there is also a provision for an emergency alarm. It means if your child is in a troubling situation, it sends notifications on your phone.",
          },
          {
            src: HealthcareImg,
            alt: "healthcare",
            title: "IoT For<br> Healthcare",
            description:
              "IoT is the perfect solution for providing intense and smart care to patients. These days, IoT wearables are reshaping the healthcare industry. Being an emerging software solution provider company, we will provide you with top-notch healthcare facilities in terms of wearables for your patients.",
          },
          {
            src: Sale,
            alt: "sales",
            title: "Point of<br> Sales",
            description:
              "A point of sale or point of purchase is the place and time at which a retail transaction takes place. The merchant calculates the amount owed by the customer, indicates that amount, may prepare an invoice for the customer, and indicates the payment options. ",
          },
        ],
      },
      big_data: {
        header: {
          heading: "Big Data",
          active_page: "Big Data",
          alt: "Big Data",
          headerimage: BigDataHeader,
        },
        banner: {
          main: "What Is Big Data?",
          para1:
            "As the name suggests, the term Big data refers to large, complex, and hard to manage data which grows exponentially with time. The data is so large in volume that even the traditional data management tools enable it to store and analyze. Additionally this massive data can be used to address business problems which can’t be easily tackled before. But here is one thing to note that it’s not the type or volume of data that’s important, it’s upto the organization how they will use the data. Furthermore, big data development can be used to analyze the insights which ultimately helps in long term decision making and also helps in improving the big strategic business moves.",
          alt: "Big Data",
          bannerimage: BigDataBanner,
        },
        significance: {
          description:
            "The importance of big data development doesn’t only stick around the volume to data but also it depends upon how the companies leveragve the Benefits of utilizing that data. Big data development helps numerous organizations in generating valuable insights. Thus, Ellocent Labs IT Solutions Pvt. Ltd. is an experienced company in employing the advanced technologies for transforming the client’s dreams into reality. We always believe in providing the cutting edge software solutions like helping the companies to use this big data into the machine learning development project for training a machine, predictive modelings and other advanced analytics applications.",
          sub: "The Role Of Big Data",
          main: "Why Is Big Data Important?",
        },
        benefit: {
          main: "How You Can Benefit It From ?",
          sub: "Our Multi Vertical Expertise",
          description:
            "We have a team of professionals data scientists, big data engineers, and developers which provide the Big data development services to its huge client base so that they can leverage of many opportunities like efficient price cost, reduce operations cost, increased sales and others marketing insights",
        },
      },
      ml: {
        header: {
          heading: "Machine Learning",
          active_page: "Machine Learning",
          alt: "Machine Learning",
          headerimage: MLHeader,
        },
        banner: {
          title: "What Is Machine Learning?",
          description:
            "Machine learning is the subset of artificial intelligence and in this digital era, many businesses incorporate Machine learning into their core practices for numerous reasons. These days many companies are already harnessing this advanced technology into their workplace to reduce cost, increase productivity, and client’s satisfaction. Additionally this technology has the potential to reshape the various industries. Ideally, with the advancement in technology machines increase accuracy, efficiency, and reduce the possibility of human error or redundancy.",
          alt: "Machine Learning",
          bannerimage: MLBanner,
        },
        significance: {
          title: "Significance Of Machine Learning",
          description:
            "Machine learning development plays a significant role in the business growth because it gives a view of trends/observance in customer behaviour, business patterns. Due to the leverage of accessing the limitless quantity of available data, affordable data storage has propelled the growth of ML. That's why various industries are shifting towards implementing Machine learning.<br> Additionally, Machine Learning tools enable organizations to identify the more profitable opportunities and also reduce the potential risk.<br>Furthermore, the various industries depend upon the large data and need a system to analyze that data, and are also embracing Machine Learning Development.",
        },
        offer: {
          subheading: "Our Multi Vertical Expertise",
          title: "Start Your Next ML Project With Our Seasoned Team",
          description:
            "We are a tight-knit team of experienced Developers, Designers, Testers, Business Analysts focused on creating innovative business solutions as we love the challenging task that involves creativity and emerging technologies.<br><br> Additionally, at Ellocent Labs IT Solutions Pvt. Ltd. we combine the strong technical abilities and strategic vision. The entire team is here to take your business to the next level.",
          alt: "Machine Learning Project",
          image: MLOffer,
        },
        mlsignificance: {
          main: "Significance Of Machine Learning",
          list: [
            {
              icon: GatheringData,
              alt: "gathering",
              title: "Gathering Data",
            },
            {
              icon: DataPre,
              alt: "data pre",
              title: "Data Pre-Processing",
            },
            {
              icon: Evaluation,
              alt: "evaluation",
              title: "Evaluation",
            },
            {
              icon: Training,
              alt: "training",
              title: "Training and<br> Testing the Model",
            },
            {
              icon: ResearchImg,
              alt: "research",
              title: "Researching the<br> model that fits well",
            },
          ],
        },
      },
      ai: {
        header: {
          headerimage: AIHeader,
          heading: "Artificial Intelligence",
          active_page: "Artificial Intelligence",
          alt: "Artificial Intelligence",
        },
        banner: {
          title: "What Is Artificial Intelligence?",
          description:
            "Ellocent Labs IT Solutions Pvt. Ltd. is a trusted, highly reputable software development company. We build scalable, robust software solutions for web, mobile, cloud and data. With our proactive approach, we are always ready to tackle your most important initiatives in business. Additionally, our vetted teams of developers provide state-of-the-art technologies for startups, enterprises and entrepreneurs as we are ready to support your vision and growth with our world-class expertise.<br><br>AI stands for Artificial Intelligence, and it’s a branch of computer science. The Artificial Intelligence Development Service aims to build smart machines or computers that have the intelligence power for decision making just like humans do. Let’s understand this concept more with some examples.",
          alt: "Artificial Intelligence",
          bannerimage: AIBanner,
          icon: BlueCircleIcon,
          list: [
            {
              title:
                "Chatbots are the new trend in the market which use the AI concept for understanding the customers problems and based on their decision making power and chatbots provide efficient solutions",
            },
            {
              title:
                "Recommendation engines can provide an automatic recommendation to the users based on their interests & behavior.",
            },
          ],
        },
        application: {
          sub: "Applications",
          main: "Artificial Intelligence Applications",
          list: [
            {
              title: "Computer<br> Vision",
              number: One,
              icon: CVision,
              alt: "Computer Vision",
            },
            {
              title: "Natural<br> Language Processing",
              number: Two,
              icon: NLP,
              alt: "Natural Language Processing",
            },
            {
              title: "Time-series<br> Analysis",
              number: Three,
              icon: TimeSeries,
              alt: "Time-series Analysis",
            },
            {
              title: "Data Analytics",
              number: Four,
              icon: DataAnalytics,
              alt: "Data Analytics",
            },
            {
              title: "Deep Learning",
              number: Five,
              icon: DataLearing,
              alt: "Deep Learning",
            },
            {
              title: "Speech<br> Recognition",
              number: Six,
              icon: SpeechRecog,
              alt: "Speech Recognition",
            },
          ],
        },
        attribute: {
          title: "Key Attributes Of Artificial Intelligence:",
        },
        keyattributes: [
          {
            id: "01",
            title: "Diminish Human Error",
            description:
              "It reduces human error and increases efficiency & precision. Machines can make decisions based on previously collected information and a set of algorithms.",
            dot: Bluedot,
          },
          {
            id: "02",
            title: "Efficient Productivity",
            description:
              "Humans need breaks. On the contrary, AI-enabled machines can perform multitasking without getting an exhausting feeling. Additionally, Artificial intelligence development services have an extra dose of efficiency, which leads to better productivity.",
            dot: Pinkdot,
          },
          {
            id: "03",
            title: "Daily Applications",
            description:
              "Daily applications such as Siri, Cortana, and Ok Google are daily used for searching out the location, making a phone call, asking for music and many more.",
            dot: Yellowdot,
          },
          {
            id: "04",
            title: "Deeper<br> Data Analysis",
            description:
              "These days, every business deals with a larger amount of data but are they making the most of them? If your answer is no, then Artificial development services are for you because AI-enabled systems can easily process & analyse a massive amount of data. ",
            dot: Purpledot,
          },
        ],
        best_fit: {
          main: "Why Are We The Best Fit For Your Next Challenging Project?",
          para1:
            "When it’s time to pick the best company for artificial intelligence development services, always go with the one with real-life experience in dealing with Artificial Intelligence development. And Here, at Ellocent Labs IT Solutions Pvt. Ltd., we have vast experience handling complex projects for various domains.",
        },
      },
      home: {
        header: {
          heading: "Major Services",
          active_page: "Major Services",
          alt: "Major Services",
          image: Major_ST,
        },
        application: {
          sub: "Applications",
          main: "Major Services Applications",
          list: [
            {
              title: "Artificial<br> Intelligence",
              number: One,
              icon: CVision,
              link: "/artificial-intelligence",
            },
            {
              title: "Machine<br> Learning",
              number: Two,
              icon: NLP,
              link: "/machine-learning",
            },
            {
              title: "Big<br> Data",
              number: Three,
              icon: TimeSeries,
              link: "/big-data",
            },
            {
              title: "Internet<br> of Things",
              number: Four,
              icon: DataAnalytics,
              link: "/internet-of-things",
            },
            {
              title: "Cryptocurrency",
              number: Five,
              icon: DataLearing,
              link: "/cryptocurrency",
            },
            {
              title: "AWS/Cloud<br> Services",
              number: Six,
              icon: SpeechRecog,
              link: "/aws-cloud-services",
            },
            {
              title: "Data<br> Science",
              number: Six,
              icon: SpeechRecog,
              link: "/data-science",
            },
            {
              title: "Bot<br> Development",
              number: Six,
              icon: SpeechRecog,
              link: "/bot-development",
            },
          ],
        },
      },
    },
  },
  help: {
    header: {
      heading: "Help",
      active_page: "Help",
      alt: "Help",
      headerimage: HelpHeader,
    },
    content: {
      title: "Ellocent Labs Support",
      contactdetail: [
        {
          text: "Call Us at",
          link: "tel:+91 7499677200",
          linktext: "+91 7499677200",
        },
        {
          text: "Email Us at",
          link: "mailto:hr@ellocentlabs.com",
          linktext: "hr@ellocentlabs.com",
        },
      ],
    },
  },
  privacypolicy: {
    header: {
      heading: "Privacy Policy",
      active_page: "Privacy Policy",
      alt: "Privacy Policy",
      headerimage: PrivacyHeader,
    },
    data: [
      {
        title: "Privacy Policy for Ellocent Labs IT Solutions Pvt. Ltd.",
        description:
          "At Ellocent Labs IT Solutions Pvt. Ltd. , accessible from <a href='https://ellocentlabs.com/'>Ellocent Labs</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Ellocent Labs IT Solutions Pvt. Ltd. and how we use it.<br>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.<br>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Ellocent Labs IT Solutions Pvt. Ltd.  This policy is not applicable to any information collected offline or via channels other than this website.",
      },
      {
        title: "Consent",
        description:
          "By using our website, you hereby consent to our Privacy Policy and agree to its terms.",
      },
      {
        title: "Information we collect",
        description:
          "The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.<br>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.<br>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.",
      },
      {
        title: "How we use your information",
        description:
          "We use the information we collect in various ways, including to:",
        icon: Liarrow,
        list: [
          {
            title: "Provide, operate, and maintain our website",
          },
          {
            title: "Improve, personalize, and expand our website",
          },
          {
            title: "Understand and analyze how you use our website",
          },
          {
            title:
              "Develop new products, services, features, and functionality",
          },
          {
            title:
              "Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes",
          },
          {
            title: "Send you emails",
          },
          {
            title: "Find and prevent fraud",
          },
        ],
      },
      {
        title: "Log Files",
        description:
          "Ellocent Labs IT Solutions Pvt. Ltd. follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.",
      },
      {
        title: "Cookies and Web Beacons",
        description:
          "Like any other website, Ellocent Labs IT Solutions Pvt. Ltd. uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.<br><br>For more general information on cookies, please read “Cookies” article from the Privacy Policy Generator.",
      },
      {
        title: "Google DoubleClick DART Cookie",
        description:
          "Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href='https://policies.google.com/technologies/ads'>https://policies.google.com/technologies/ads</a>",
      },
      {
        title: "Our Advertising Partners",
        description:
          "Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.<br><br>Google<br><a href='https://policies.google.com/technologies/ads'>https://policies.google.com/technologies/ads</a>",
      },
      {
        title: "Advertising Partners Privacy Policies",
        description:
          "You may consult this list to find the Privacy Policy for each of the advertising partners of Ellocent Labs IT Solutions Pvt. Ltd.<br><br>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Ellocent Labs IT Solutions Pvt. Ltd. , which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.<br><br>Note that Ellocent Labs IT Solutions Pvt. Ltd. has no access to or control over these cookies that are used by third-party advertisers.",
      },
      {
        title: "Third Party Privacy Policies",
        description:
          "Ellocent Labs IT Solutions Pvt. Ltd. ‘s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.<br><br>Some content or applications on the Website may be served by third-parties, including content and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.<br><br>We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about any targeted content, you should contact the responsible provider directly.<br><br>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.",
      },
      {
        title: "CCPA Privacy Rights (Do Not Sell My Personal Information)",
        description:
          "Under the CCPA, among other rights, California consumers have the right to:<br><br>Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.<br><br>Request that a business delete any personal data about the consumer that a business has collected.<br><br>Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.<br><br>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.",
      },
      {
        title: "GDPR Data Protection Rights",
        description:
          "We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:",
      },
      {
        description:
          "<b>The right to access –</b> You have the right to request copies of your personal data. We may charge you a small fee for this service.",
      },
      {
        description:
          "<b>The right to rectification –</b> You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.",
      },
      {
        description:
          "<b> The right to erasure –</b> You have the right to request that we erase your personal data, under certain conditions.",
      },
      {
        description:
          "<b>The right to restrict processing –</b> You have the right to request that we restrict the processing of your personal data, under certain conditions.",
      },
      {
        description:
          "<b>The right to object to processing –</b> You have the right to object to our processing of your personal data, under certain conditions.",
      },
      {
        description:
          "<b>The right to data portability –</b> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
      },
      {
        description:
          "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.",
      },
      {
        description:
          "<b>Children Under the Age of 16</b><br>Our Website is not intended for children under 16 years of age. No one under age 16 may provide any information to or on the Website. We do not knowingly collect or solicit personal information from children under age 16. If you are under 16, do not use or provide any information on the Website or through any of its features, or register on or use any of the interactive or public comment features of the Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under age 16 without verification of parental consent, we will delete that information.",
      },
    ],
  },
  terms: {
    header: {
      headerimage: PrivacyHeader,
      heading: "Terms & Conditions",
      active_page: "Terms & Conditions",
      alt: "Terms & Conditions",
    },
  },
};
